import React, { useState, useContext, useEffect } from "react";
import "./InspectionsForm.css";
import Dropdown from "../AppComponents/Dropdown/Dropdown";
import Multiselect from "../AppComponents/Multiselect/Multiselect";
import Input from "../AppComponents/Input/Input";
import { Context } from "../App";
import {
  getUserInfo,
  getServiceRequestEmployees,
  getAllStates,
  updateInspectionSubmission,
  postLargeFiles,
} from "../API/localAPI";
import FormSection from "../AppComponents/FormSection/FormSection";
import Autocomplete from "../AppComponents/AutoComplete/AutoComplete";
import ProjectSearch from "../ProjectSearch/ProjectSearch";
import Button from "../AppComponents/Button/Button";

export default function InspectionsForm() {
  // State
  const {
    setValid,
    setError,
    foundProject,
    didSearch,
    unixVal,
    projNumber,
    submissionId,
    setDidSearch,
    inspectionsFormData,
    setInspectionsFormData,
    setLoading,
  } = useContext(Context);

  const [employees, setEmployees] = useState({});
  const [states, setStates] = useState({});

  useEffect(() => {
    getServiceRequestEmployees()
      .then((res) => setEmployees(res.data.value))
      .catch((err) => setError("Failed to load employees. Please reload"));
    getAllStates()
      .then((res) => {
        setStates(res.data.value);
      })
      .catch((err) => setError("Failed to load states. Please reload"));
  }, []);

  // Handlers
  const handleStateChange = (e, n) => {
    if (n) {
      setInspectionsFormData((prev) => {
        return {
          ...prev,
          mts_state: n.mts_stateid,
        };
      });
    } else {
      setInspectionsFormData((prev) => {
        return {
          ...prev,
          mts_state: "",
        };
      });
    }
  };

  const handleTechnicianChange = (e, n) => {
    if (n) {
      setInspectionsFormData((prev) => {
        return {
          ...prev,
          mts_techname: n.contactid,
        };
      });
    } else {
      setInspectionsFormData((prev) => {
        return {
          ...prev,
          mts_techname: "",
        };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const cleanedBody = { ...inspectionsFormData };

    Object.keys(cleanedBody).forEach((key) => {
      if (key.includes("img_")) {
        delete cleanedBody[key];
      }
    });
    // delete cleanedBody.img_electric_sticker;
    // delete cleanedBody.img_building_sticker;
    // delete cleanedBody.img_fire_sticker;
    // delete cleanedBody.img_photo_1;
    // delete cleanedBody.img_photo_2;
    // delete cleanedBody.img_photo_3;
    // delete cleanedBody.img_photo_4;
    // delete cleanedBody.img_photo_5;
    // delete cleanedBody.img_photo_6;
    // delete cleanedBody.img_photo_7;
    // delete cleanedBody.img_photo_8;
    // delete cleanedBody.img_photo_9;
    // delete cleanedBody.img_photo_10;
    // delete cleanedBody.img_photo_11;
    // delete cleanedBody.img_photo_12;
    // delete cleanedBody.img_photo_13;
    // delete cleanedBody.img_photo_14;
    // delete cleanedBody.img_photo_15;
    // delete cleanedBody.img_photo_16;
    // delete cleanedBody.img_photo_17;
    // delete cleanedBody.img_photo_18;
    // delete cleanedBody.img_photo_19;
    // delete cleanedBody.img_photo_20;
    cleanedBody.mts_inspectiontype =
      inspectionsFormData.mts_inspectiontype.join(",");

    const images = {
      img_electric_sticker: inspectionsFormData.img_electric_sticker,
      img_building_sticker: inspectionsFormData.img_building_sticker,
      img_fire_sticker: inspectionsFormData.img_fire_sticker,
      img_photo_1: inspectionsFormData.img_photo_1,
      img_photo_2: inspectionsFormData.img_photo_2,
      img_photo_3: inspectionsFormData.img_photo_3,
      img_photo_4: inspectionsFormData.img_photo_4,
      img_photo_5: inspectionsFormData.img_photo_5,
      img_photo_6: inspectionsFormData.img_photo_6,
      img_photo_7: inspectionsFormData.img_photo_7,
      img_photo_8: inspectionsFormData.img_photo_8,
      img_photo_9: inspectionsFormData.img_photo_9,
      img_photo_10: inspectionsFormData.img_photo_10,
      img_photo_11: inspectionsFormData.img_photo_11,
      img_photo_12: inspectionsFormData.img_photo_12,
      img_photo_13: inspectionsFormData.img_photo_13,
      img_photo_14: inspectionsFormData.img_photo_14,
      img_photo_15: inspectionsFormData.img_photo_15,
      img_photo_16: inspectionsFormData.img_photo_16,
      img_photo_17: inspectionsFormData.img_photo_17,
      img_photo_18: inspectionsFormData.img_photo_18,
      img_photo_19: inspectionsFormData.img_photo_19,
      img_photo_20: inspectionsFormData.img_photo_20,
    };

    const constructImageObjects = (images) => {
      const imageObjects = Object.keys(images)
        .filter((key) => images[key] && images[key].length > 0)
        .map((key) => {
          return {
            documentBody: images[key],
            imageName: key,
          };
        });

      // console.log('imageObjects', imageObjects)
      return imageObjects;
    };

    updateInspectionSubmission(
      unixVal,
      projNumber,
      foundProject.iis_projectid,
      cleanedBody
    )
      .then(() => {
        console.log("submissionid", submissionId);
        postLargeFiles(
          "objectid_mts_inspectionformsubmission@odata.bind",
          "mts_inspectionformsubmissions",
          submissionId,
          constructImageObjects(images)
        );
        setLoading(false);
        setValid("Success");
        setDidSearch(false);
        setInspectionsFormData({
          mts_inspectiondate: "",
          mts_state: "",
          mts_techname: "",
          mts_technameifnotshown: "",
          mts_inspectorname: "",
          mts_inspectiontype: [],
          mts_inspectionstatus: "911890000",
          mts_cleanjobsite: false,
          mts_isalldatacorrect: false,
          mts_inspectiondetails: "",
          mts_jobsitecleanlinessnotes: "",
          mts_fixmadeonsitenotes: "",
          img_electric_sticker: {},
          img_building_sticker: {},
          img_fire_sticker: {},
          img_photo_1: {},
          img_photo_2: {},
          img_photo_3: {},
          img_photo_4: {},
          img_photo_5: {},
          img_photo_6: {},
          img_photo_7: {},
          img_photo_8: {},
          img_photo_9: {},
          img_photo_10: {},
          img_photo_11: {},
          img_photo_12: {},
          img_photo_13: {},
          img_photo_14: {},
          img_photo_15: {},
          img_photo_16: {},
          img_photo_17: {},
          img_photo_18: {},
          img_photo_19: {},
          img_photo_20: {},
        });
      })
      .catch((err) => {
        setLoading(false);
        setError("Unsuccessful. Try again");
        alert(err);
      });
  };

  // JSX Elements
  const inspectionInformation = (
    <section className="inputs">
      <Input
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_inspectiondate"
        title="Inspection Date"
        type="date"
      />
      <Autocomplete
        label={"State"}
        options={states}
        onChange={handleStateChange}
        labelKey={"mts_name"}
      />
      <Autocomplete
        label={"Technician Name"}
        options={employees}
        onChange={handleTechnicianChange}
        labelKey={"fullname"}
      />
      <Input
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_technameifnotshown"
        title="Technician Name (if not shown)"
      />
      <Input
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_inspectorname"
        title="Inspector Name (First and Last)"
      />
      <Multiselect
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_inspectiontype"
        title="Inspection Type"
        options={[
          { name: "Building", value: "911890000" },
          { name: "Electrical", value: "911890001" },
          { name: "Fire", value: "962080003" },
          { name: "In Progress", value: "962080000" },
          { name: "Plumbing", value: "911890004" },
          { name: "PTO", value: "100000000" },
          { name: "Roof", value: "962080002" },
          { name: "Rough", value: "911890002" },
          { name: "Trench", value: "911890003" },
          { name: "Utility", value: "100000001" },
          { name: "Work With", value: "962080001" },
        ]}
      />
      <Dropdown
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_inspectionstatus"
        title="Inspection Status"
        options={[
          { name: "Cancelled", value: "911890000" },
          { name: "Certifiable Fail", value: "100000000" },
          { name: "Disputed", value: "100000002" },
          { name: "Failed", value: "911890002" },
          { name: "Overturned", value: "962080000" },
          { name: "Partial Pass", value: "100000001" },
          { name: "Passed", value: "911890001" },
        ]}
      />
      <Dropdown
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_cleanjobsite"
        title="Clean Job site"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Dropdown
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_isalldatacorrect"
        title="Is All Data Correct (Review Before Submitting)"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
    </section>
  );

  const notesSection = (
    <section className="inputs">
      <Input
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_inspectiondetails"
        title="Inspection Details"
        type="textarea"
      />
      <Input
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_jobsitecleanlinessnotes"
        title="Job Site Cleanliness Notes"
        type="textarea"
      />
      <Input
        formName="inspectionsFormData"
        stateSetter={setInspectionsFormData}
        id="mts_fixmadeonsitenotes"
        title="Fix Made on Site Notes"
        type="textarea"
      />
    </section>
  );

  const imagesSection = (
    <article className="photos-container">
      <section className="photos">
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_electric_sticker"
          title="Electric Sticker"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_building_sticker"
          title="Building Sticker"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_fire_sticker"
          title="Fire Sticker"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_1"
          title="Photo 1"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_2"
          title="Photo 2"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_3"
          title="Photo 3"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_4"
          title="Photo 4"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_5"
          title="Photo 5"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_6"
          title="Photo 6"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_7"
          title="Photo 7"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_8"
          title="Photo 8"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_9"
          title="Photo 9"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_10"
          title="Photo 10"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_11"
          title="Photo 11"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_12"
          title="Photo 12"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_13"
          title="Photo 13"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_14"
          title="Photo 14"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_15"
          title="Photo 15"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_16"
          title="Photo 16"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_17"
          title="Photo 17"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_18"
          title="Photo 18"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_19"
          title="Photo 19"
          type="file"
        />
        <Input
          formName="inspectionsFormData"
          stateSetter={setInspectionsFormData}
          id="img_photo_20"
          title="Photo 20"
          type="file"
        />
      </section>
    </article>
  );

  return !didSearch ? (
    <ProjectSearch
      endpoint="new_inspection_submission"
      idForPhoto="mts_inspectionformsubmissionid"
    />
  ) : (
    <form onSubmit={handleSubmit} className="inspection-form">
      <FormSection
        title="Inspection Information"
        children={inspectionInformation}
      />
      <FormSection title="Notes" children={notesSection} />
      <FormSection title="Image Uploads" children={imagesSection} />
      <Button onClick={handleSubmit} type={"submit"} title={"Submit"} />
    </form>
  );
}
