import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Asterisk from "../../Styling/Asterisk";

// options - array of objects that autocomplete uses to set one in state
// onChange - state setter for desired piece of context
// labelKey - key on object that has label for autocomplete search

export default function AutoComplete({ label, options, onChange, labelKey, required }) {
  return (
    <article className="input-wrapper">
      <label style={{fontWeight: "normal", fontSize: "medium", fontFamily: "GothamMedium", marginBottom: 10}} className="autocomplete-label">
        {label} {required && <Asterisk />}
      </label>
      <Autocomplete
        options={options}
        onChange={onChange}
        getOptionLabel={(option) => option[labelKey]}
        style={{
          marginBottom: 20,
          minWidth: 200,
          width: '90%',
          maxWidth: 600,
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "5px",
          height: 40,
          boxShadow:
            "0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15)",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        )}
      />
    </article>
  );
}
