import React, { useState, useContext, useEffect } from "react";
import "./ServiceRequestForm.css";
import Dropdown from "../../../AppComponents/Dropdown/Dropdown";
import Multiselect from "../../../AppComponents/Multiselect/Multiselect";
import Input from "../../../AppComponents/Input/Input";
import { Context } from "../../../App";
import {
  getUserInfo,
  newServiceRequest,
  getServiceRequestDropdowns,
  getServiceRequestEmployees,
  updateServiceRequest,
  postLargeFiles,
} from "../../../API/localAPI";
import FormSection from "../../../AppComponents/FormSection/FormSection";
import Button from "../../../AppComponents/Button/Button";
import Autocomplete from "../../../AppComponents/AutoComplete/AutoComplete";
import { Redirect } from "react-router-dom";
import AdditionalPhotos from "../../../GlobalComponents/AdditionalPhotos/AdditionalPhotos";

export default function ServiceRequestForm() {
  // State
  const {
    serviceRequestFormData,
    setServiceRequestFormData,
    setValid,
    setError,
    setLoading,
  } = useContext(Context);
  const [didSearch, setDidSearch] = useState(false);
  const [projNumber, setProjNumber] = useState("");
  const [foundProject, setFoundProject] = useState({});
  const [dropdowns, setDropdowns] = useState({});
  const [employees, setEmployees] = useState({});
  const [unixVal, setUnixVal] = useState(12345);
  const [submissionId, setSubmissionId] = useState(12345);

  // on load
  useEffect(() => {
    getServiceRequestDropdowns()
      .then((res) => {
        setDropdowns(res.data);
      })
      .catch((err) => setError("Failed to load dropdowns. Please reload."));
    getServiceRequestEmployees()
      .then((response) => {
        setEmployees(response.data.value);
      })
      .catch((err) => setError("Failed to load employees. Please reload."));
  }, []);

  // Handlers
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChange = async (e) => {
    e.preventDefault();
    if (e.target.type === "file") {
      let base = await convertBase64(e.target.files[0]);
      let valid = base.split(",")[1];
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          [e.target.id]: valid,
        };
      });
    } else {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          [e.target.id]: e.target.value,
        };
      });
    }
  };

  const handleStateChange = (e, n) => {
    if (n) {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_statelookup: n.mts_stateid,
        };
      });
    } else {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_statelookup: "",
        };
      });
    }
  };

  const handleOfficeChange = (e, n) => {
    if (n) {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_office: n.mts_momentumofficeid,
        };
      });
    } else {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_office: "",
        };
      });
    }
  };

  const handleCrewChange = (e, n) => {
    if (n) {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_omcrew: n.mts_crewid,
        };
      });
    } else {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_omcrew: "",
        };
      });
    }
  };

  const handleCrewMember1Change = (e, n) => {
    if (n) {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_crewmember1: n.contactid,
        };
      });
    } else {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_crewmember1: "",
        };
      });
    }
  };

  const handleCrewMember2Change = (e, n) => {
    if (n) {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_crewmember2: n.contactid,
        };
      });
    } else {
      setServiceRequestFormData((prev) => {
        return {
          ...prev,
          mts_crewmember2: "",
        };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const cleanedBody = { ...serviceRequestFormData };
    delete cleanedBody.img_before_service;
    delete cleanedBody.img_after_service;
    delete cleanedBody.img_envoy_green_light;
    delete cleanedBody.img_micro_confirmation;
    delete cleanedBody.additional_photo_1;
    delete cleanedBody.additional_photo_2;
    delete cleanedBody.additional_photo_3;
    delete cleanedBody.additional_photo_4;
    delete cleanedBody.additional_photo_5;
    delete cleanedBody.additional_photo_6;
    delete cleanedBody.additional_photo_7;
    delete cleanedBody.additional_photo_8;
    delete cleanedBody.additional_photo_9;
    delete cleanedBody.additional_photo_10;
    delete cleanedBody.additional_photo_11;
    delete cleanedBody.additional_photo_12;
    delete cleanedBody.additional_photo_13;
    delete cleanedBody.additional_photo_14;
    delete cleanedBody.additional_photo_15;

    cleanedBody.mts_equipmentreplaced =
      serviceRequestFormData.mts_equipmentreplaced.join(",");
    cleanedBody.mts_microtype = serviceRequestFormData.mts_microtype.join(",");

    const images = {
      img_before_service: serviceRequestFormData.img_before_service,
      img_after_service: serviceRequestFormData.img_after_service,
      img_envoy_green_light: serviceRequestFormData.img_envoy_green_light,
      img_micro_confirmation: serviceRequestFormData.img_micro_confirmation,
      additional_photo_1: serviceRequestFormData.additional_photo_1,
      additional_photo_2: serviceRequestFormData.additional_photo_2,
      additional_photo_3: serviceRequestFormData.additional_photo_3,
      additional_photo_4: serviceRequestFormData.additional_photo_4,
      additional_photo_5: serviceRequestFormData.additional_photo_5,
      additional_photo_6: serviceRequestFormData.additional_photo_6,
      additional_photo_7: serviceRequestFormData.additional_photo_7,
      additional_photo_8: serviceRequestFormData.additional_photo_8,
      additional_photo_9: serviceRequestFormData.additional_photo_9,
      additional_photo_10: serviceRequestFormData.additional_photo_10,
      additional_photo_11: serviceRequestFormData.additional_photo_11,
      additional_photo_12: serviceRequestFormData.additional_photo_12,
      additional_photo_13: serviceRequestFormData.additional_photo_13,
      additional_photo_14: serviceRequestFormData.additional_photo_14,
      additional_photo_15: serviceRequestFormData.additional_photo_15,
    };

    const constructImageObjects = (images) => {
      const imageObjects = Object.keys(images)
        .filter((key) => images[key] && images[key].length > 0)
        .map((key) => {
          return {
            documentBody: images[key],
            imageName: key,
          };
        });

      // console.log('imageObjects', imageObjects)
      return imageObjects;
    };

    updateServiceRequest(
      unixVal,
      projNumber,
      foundProject.iis_projectid,
      cleanedBody
    )
      .then(() => {
        setLoading(false);
        console.log("IMAGES", images);
        postLargeFiles(
          "objectid_mts_servicemachformsubmission@odata.bind",
          "mts_servicemachformsubmissions",
          submissionId,
          constructImageObjects(images)
        );
      })
      .catch((err) => {
        setLoading(false);
        setError("Unsuccessful. Try Again");
        alert(err);
      })
      .then(() => {
        setDidSearch(false);
        setValid("Success");
        setServiceRequestFormData({
          mts_servicecategory: "962080000",
          mts_statelookup: "",
          mts_office: "",
          mts_customername: "",
          mts_dateofservicecall: "",
          mts_omcrew: "",
          mts_crewcount: "",
          mts_crewmember1: "",
          mts_crewmember2: "",
          mts_otherpleaselist: "",
          mts_rootcauseofservice: "962080000",
          mts_truckrolltype: "962080000",
          mts_serviceoutcome: "962080000",
          mts_traveltimeonewayhours: "",
          mts_totaltimeonsitehours: "",
          mts_equipmentreplaced: [],
          mts_wasanydefectiveequipmentreplaced: false,
          mts_rmaformicroreplacement: false,
          mts_rmanumber: "",
          mts_microtype: [],
          mts_numberofmicrosreplaced: "0",
          mts_numberoffusesreplaced: "0",
          mts_numberofbreakersreplaced: "0",
          mts_trunkcablelengthreplacedfeet: "0",
          mts_numberofweathercapsreplaced: "0",
          mts_numberofqconnectorsreplaced: "0",
          mts_additionalnotes: "",
          img_before_service: {},
          img_after_service: {},
          img_envoy_green_light: {},
          img_micro_confirmation: {},
        });
      });
  };

  const clickedYes = (e) => {
    e.preventDefault();
    // console.log(projNumber)

    newServiceRequest(projNumber)
      .then((v) => {
        setDidSearch(true);
        setUnixVal(v.unix);
        setSubmissionId(v.mts_servicemachformsubmissionid);
      })
      .catch((e) => {
        setDidSearch(false);
        alert(`Please try again. Error: ${e}`);
        console.log(e);
      });
  };
  const clickedNo = (e) => {
    e.preventDefault();
    setDidSearch(false);
    setProjNumber("");
    setFoundProject({});
  };

  const searchClick = async (e) => {
    e.preventDefault();
    if (projNumber === "") {
      setError("make sure to type a project number");
    } else {
      let found = await getUserInfo(projNumber);
      // console.log(found)
      if (found.length > 0) {
        // console.log('start')
        setFoundProject(found[0]);
      }
    }
  };

  return !didSearch ? (
    <div
      className="initial_search_div"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
        borderRadius: "5px",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {Object.keys(foundProject).length === 0 && (
        <>
          <input
            style={{
              margin: 10,
              marginTop: "1em",
              marginBottom: "1em",
              width: "200px",
              padding: "1em",
            }}
            value={projNumber}
            onChange={(e) => setProjNumber(e.currentTarget.value)}
            placeholder="search for a project number"
          />
          <button
            style={{
              padding: "0.5em",
              marginBottom: "0.5em",
              border: "none",
              borderRadius: "7px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
              color: "white",
              fontSize: "0.9375rem",
              fontWeight: "600",
              backgroundColor: "rgb(24 119 242)",
            }}
            onClick={searchClick}
          >
            search
          </button>
        </>
      )}
      {Object.keys(foundProject).length > 0 && (
        <>
          <p style={{ marginTop: "0.5em", fontWeight: "bold" }}>
            Is this the correct customer?
          </p>
          <div
            style={{
              background: "#add8e6",
              padding: "0.7em",
              margin: 10,
              marginTop: "0.5em",
              marginBottom: "0.5em",
              borderRadius: "8px",
            }}
          >
            <p>{foundProject.iis_householdid.name}</p>
            <p>{`${foundProject.iis_householdid.address1_line1}, ${foundProject.iis_householdid.address1_city}, ${foundProject.iis_householdid["iis_state@OData.Community.Display.V1.FormattedValue"]}`}</p>
            {/* <p>{foundProject.iis_householdid.telephone2}</p> */}

            <button
              onClick={clickedYes}
              style={{
                width: "100px",
                padding: "0.5em",
                marginRight: "0.5em",
                marginTop: "0.5em",
                border: "none",
                borderRadius: "7px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                color: "white",
                fontSize: "0.9375rem",
                fontWeight: "600",
                backgroundColor: "#25d366",
              }}
            >
              Yes
            </button>
            <button
              onClick={clickedNo}
              style={{
                width: "100px",
                padding: "0.5em",
                border: "none",
                borderRadius: "7px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                color: "white",
                fontSize: "0.9375rem",
                fontWeight: "600",
                backgroundColor: "#FF5A5F",
              }}
            >
              No
            </button>
          </div>
        </>
      )}
    </div>
  ) : (
    <form onSubmit={handleSubmit}>
      <FormSection title="Project Information">
        <Dropdown
          required
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_servicecategory"
          title="Service Category"
          options={[
            { name: "Electrical", value: "962080000" },
            { name: "O&M", value: "962080001" },
            { name: "Construction/Roof", value: "962080002" },
            { name: "MPS", value: "962080003" },
          ]}
        />
        <Autocomplete
          label={"State"}
          options={dropdowns.mts_states}
          onChange={handleStateChange}
          labelKey={"mts_name"}
        />
        <Autocomplete
          label={"Office"}
          options={dropdowns.mts_momentumoffices}
          onChange={handleOfficeChange}
          labelKey={"mts_name"}
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          id="mts_customername"
          title="Customer Name"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          type="date"
          id="mts_dateofservicecall"
          title="Date of Service Call"
        />
      </FormSection>
      <FormSection title="Crew / Service">
        <Autocomplete
          label={"O&M Crew"}
          options={dropdowns.mts_crews}
          onChange={handleCrewChange}
          labelKey={"mts_name"}
        />

        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          id="mts_crewcount"
          title="Crew Count"
          type="number"
        />
        <Autocomplete
          required
          label={"Crew Member 1"}
          options={dropdowns.contacts}
          onChange={handleCrewMember1Change}
          labelKey={"fullname"}
        />
        <Autocomplete
          label={"Crew Member 2"}
          options={dropdowns.contacts}
          onChange={handleCrewMember2Change}
          labelKey={"fullname"}
        />

        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_otherpleaselist"
          title="OTHER PLEASE LIST"
        />
        <Dropdown
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_rootcauseofservice"
          title="Root Cause of Service"
          options={[
            { name: "Existing Roof", value: "962080000" },
            { name: "Momentum Roof", value: "962080001" },
            { name: "Solar Install Related", value: "962080002" },
            { name: "Critter Guard", value: "962080003" },
            { name: "Snow Guard", value: "962080004" },
            { name: "O&M", value: "962080005" },
          ]}
        />
        <Dropdown
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_truckrolltype"
          title="Truck Roll Type"
          options={[
            { name: "Install", value: "962080000" },
            { name: "Removal/Reinstall", value: "962080001" },
            { name: "Service", value: "962080002" },
            { name: "O&M", value: "962080003" },
            { name: "Inspection", value: "962080004" },
            { name: "Construction", value: "962080005" },
            { name: "Site Assessment", value: "962080006" },
            { name: "Service Add-On", value: "962080007" },
          ]}
        />
        <Dropdown
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          id="mts_serviceoutcome"
          title="Service Outcome"
          options={[
            { name: "Issue Resolved", value: "962080000" },
            { name: "Issue Persists", value: "962080001" },
            { name: "Return Needed", value: "962080002" },
          ]}
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          id="mts_traveltimeonewayhours"
          title="Travel Time One Way (Hours)"
          type="number"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          id="mts_totaltimeonsitehours"
          title="Total Time On Site (Hours)"
          type="number"
        />
      </FormSection>
      <FormSection title="Equipment">
        <Multiselect
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_equipmentreplaced"
          title="Equipment Replaced"
          options={[
            { name: "Micros", value: "962080000" },
            { name: "Envoy", value: "962080001" },
            { name: "Cell Modem", value: "962080002" },
            { name: "Meter", value: "962080003" },
            { name: "Sense", value: "962080004" },
            { name: "Fuses", value: "962080005" },
            { name: "Trunk Cable", value: "962080006" },
            { name: "Weather Caps", value: "962080007" },
            { name: "Q Connectors", value: "962080008" },
          ]}
        />
        <Dropdown
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          id="mts_wasanydefectiveequipmentreplaced"
          title="Was any defective equipment replaced?"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Dropdown
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          required
          id="mts_rmaformicroreplacement"
          title="RMA for Micro Replacement?"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_rmanumber"
          title="RMA Number"
          type="number"
        />
        <Multiselect
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_microtype"
          title="Micro Type"
          options={[
            { name: "IQ6", value: "962080000" },
            { name: "IQ6+", value: "962080001" },
            { name: "IQ7", value: "962080002" },
          ]}
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_numberofmicrosreplaced"
          title="Number of Micros Replaced"
          type="number"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_numberoffusesreplaced"
          title="Number of Fuses Replaced"
          type="number"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_numberofbreakersreplaced"
          title="Number of Breakers Replaced"
          type="number"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_trunkcablelengthreplacedfeet"
          title="Trunk Cable Length Replaced (Feet)"
          type="number"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_numberofweathercapsreplaced"
          title="Number of Weather Caps Replaced"
          type="number"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_numberofqconnectorsreplaced"
          title="Number of Q Connectors Replaced"
          type="number"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="mts_additionalnotes"
          title="Additional Notes"
          type="textarea"
        />
      </FormSection>
      <FormSection title="Image Uploads">
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="img_before_service"
          title="Before Service"
          type="file"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="img_after_service"
          title="After Service"
          type="file"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="img_envoy_green_light"
          title="Envoy Green Light"
          type="file"
        />
        <Input
          formName="serviceRequestFormData"
          stateSetter={setServiceRequestFormData}
          id="img_micro_confirmation"
          title="Micro Confirmation"
          type="file"
        />
      </FormSection>
      <AdditionalPhotos
        state={serviceRequestFormData}
        stateSetter={setServiceRequestFormData}
        required={false}
      />
      <Button onClick={handleSubmit} type={"submit"} title={"Submit"} />
    </form>
  );
}
