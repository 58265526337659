import React, { useState, useContext, useEffect } from "react";
import Dropdown from "../AppComponents/Dropdown/Dropdown";
import Input from "../AppComponents/Input/Input";
import { Context } from "../App";
import {
  getOffices,
  getServiceRequestEmployees,
  getAllStates,
  updateFieldSupervisionChecklist,
  postLargeFiles,
} from "../API/localAPI";
import FormSection from "../AppComponents/FormSection/FormSection";
import Autocomplete from "../AppComponents/AutoComplete/AutoComplete";
import ProjectSearch from "../ProjectSearch/ProjectSearch";
import Button from "../AppComponents/Button/Button";

export default function FieldSupervision() {
  // State
  const {
    foundProject,
    didSearch,
    unixVal,
    projNumber,
    projectNumVal,
    fieldSupervisionData,
    setFieldSupervisionData,
    submissionId,
    setDidSearch,
    setError,
    setLoading,
    setValid,
  } = useContext(Context);

  const [employees, setEmployees] = useState({});
  const [states, setStates] = useState({});
  const [offices, setOffices] = useState({});

  useEffect(() => {
    getServiceRequestEmployees()
      .then((res) => setEmployees(res.data.value))
      .catch((err) => {
        alert(err);
        setError("Failed to load employees. Please reload.")
      });
    getAllStates()
      .then((res) => {
        setStates(res.data.value);
      })
      .catch((err) => {
        alert(err);
        setError("Failed to load states. Please reload.")
      });
    getOffices()
      .then((res) => {
        setOffices(res);
      })
      .catch((err) => {
        alert(err);
        setError("Failed to load offices. Please reload.")
      });
  }, []);

  //Handlers
  const handleForemanChange = (e, n) => {
    if (n) {
      setFieldSupervisionData((prev) => {
        return {
          ...prev,
          mts_foreman: n.contactid,
        };
      });
    } else {
      setFieldSupervisionData((prev) => {
        return {
          ...prev,
          mts_foreman: "",
        };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(fieldSupervisionData);
    const cleanedBody = { ...fieldSupervisionData };
    const images = {};

    Object.keys(fieldSupervisionData).forEach((key) => {
      if (key.includes("img") && fieldSupervisionData[key]) {
        images[key] = fieldSupervisionData[key];
      }
    });

    console.log(projectNumVal);

    const constructImageObjects = (images) => {
      const imageObjects = Object.keys(images)
        .filter((key) => images[key] && images[key].length > 0)
        .map((key) => {
          return {
            documentBody: images[key],
            imageName: key,
          };
        });

      // console.log('imageObjects', imageObjects)
      return imageObjects;
    };

    updateFieldSupervisionChecklist(
      unixVal,
      projNumber,
      foundProject.iis_projectid,
      cleanedBody
    )
      .then(() => {
        console.log("images", images);
        postLargeFiles(
          "objectid_mts_fieldsupervisionchecklist@odata.bind",
          "mts_fieldsupervisionchecklists",
          submissionId,
          constructImageObjects(images)
        );
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      })
      .then(() => {
        setLoading(false);
        setValid("Success");
        setDidSearch(false);
        setFieldSupervisionData({
          mts_customerelectricapproval: false,
          mts_customerpanelapproval: false,
          mts_onsiterevision: false,
          mts_safetysitemapuploaded: false,
          mts_mliftrequired: false,
          mts_jobsiteclean: false,
          mts_photoschecked: false,
        });
      });
  };

  return !didSearch ? (
    <ProjectSearch
      endpoint="new_field_supervision_checklist"
      idForPhoto="mts_fieldsupervisionchecklistid"
    />
  ) : (
    <form className="field-supervision-form" onSubmit={handleSubmit}>
      <FormSection title="General">
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          type="date"
          title="Date"
          id="mts_date"
        />

        <Autocomplete
          label={"Foreman"}
          options={employees}
          onChange={handleForemanChange}
          labelKey={"fullname"}
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          type="textarea"
          title="Full Name of Techs Onsite"
          id="mts_techsonsite"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          title="Truck Number"
          id="mts_trucknumber"
        />
      </FormSection>
      <FormSection title="Jobsite Arrival">
        <Dropdown
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          id="mts_customerelectricapproval"
          title="Was the electrical equipment layout and pipe run location approved by customer?"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Dropdown
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          id="mts_customerpanelapproval"
          title="Was the panel layout and roof pipe run approved by customer?"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="mts_customerrejectreason"
          type="textarea"
          title="If answered no to any explain below"
        />
        <Dropdown
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          id="mts_onsiterevision"
          title="Onsite revision required"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="mts_onsiterevisionreason"
          type="textarea"
          title="If Yes, Why?"
        />
      </FormSection>
      <FormSection title="Safety">
        <Dropdown
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          id="mts_safetysitemapuploaded"
          title="Safety Site Map created, posted, and uploaded to Dropbox"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="safety_site_map_img"
          title="Safety Site Map"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="ladder_img"
          title="Ladder Strapped"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="ladder_2_img"
          title="Ladder Strapped 2"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="anchor_img"
          title="Sufficient Anchor Placed"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="anchor_2_img"
          title="Sufficient Anchor Placed 2"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="safety_img"
          title="Harness and Rope Properly Worn"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="safety_2_img"
          title="Harness and Rope Properly Worn 2"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="electrical_img"
          title="Electrical Safety"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="electrical_2_img"
          title="Electrical Safety 2"
          type="file"
        />
        <Dropdown
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          id="mts_mliftrequired"
          title="M-lift required (if yes include photo)"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="mlift_img"
          title="M-lift required"
          type="file"
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="mlift_2_img"
          title="M-lift 2"
          type="file"
        />
      </FormSection>
      <FormSection title="Training">
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="mts_trainingnotes"
          type="textarea"
          title="Celebrate successes and identify areas of opportunity"
        />
        <Dropdown
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          id="mts_jobsiteclean"
          title="Jobsite clean/organized"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Dropdown
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          required
          id="mts_photoschecked"
          title="Photos Checked"
          options={[
            { name: "No", value: false },
            { name: "Yes", value: true },
          ]}
        />
        <Input
          formName="fieldSupervisionData"
          stateSetter={setFieldSupervisionData}
          id="mts_customercomments"
          type="textarea"
          title="Customer comments/concerns"
        />
      </FormSection>
      <Button onClick={handleSubmit} type={'submit'} title={"Submit"} />
    </form>
  );
}
