import React from "react";
import SuccessStyle from "./styles";

const Success = (props) => {
  return (
    <div
      style={{
        background: "white",
        width: "200px",
        height: "200px",
        borderRadius: "7px",
      }}
    >
      <SuccessStyle>
        <div class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
      </SuccessStyle>
    </div>
  );
};

export default Success;
