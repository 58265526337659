import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AuthContextProvider from "./Auth/AuthContext";
import AzureAuth from "./Auth/AzureAuth";

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <AzureAuth>
        <App />
      </AzureAuth>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
