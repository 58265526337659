import React from "react";
import ms from "./ms.png";

//styles
import { LoginB } from "./styles";

const LoginButton = ({ onClick }) => {
  return (
    <LoginB onClick={onClick}>
      <img src={ms} alt="Microsoft logo for login button" />
      Sign in with Microsoft
    </LoginB>
  );
};

export default LoginButton;
