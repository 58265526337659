import "./Header.css";
import React from "react";

export default function Header({ title }) {
  return (
    <header>
      {title}
    </header>
  );
}
