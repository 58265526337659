import React, { useState, useContext, useEffect } from "react";
import "./Input.css";
import Asterisk from "../../Styling/Asterisk";
import dayjs from "dayjs";
import dayjsPluginUTC from 'dayjs-plugin-utc';

dayjs.extend(dayjsPluginUTC);

// formData - id in context used to store this forms data
// stateSetter - function linking to state setter in context
// id - id of specific field the input is linked to
// title - label for the input
// type - type of input (date, number, textarea, file, default = text)
// required - boolean that adds UI asterisk and prop to input

export default function Input({
  formName,
  stateSetter,
  id,
  title,
  type,
  required,
  safe
}) {
  const getProp = () => {};

  const requiredFields = [
    "mts_customername",
    "mts_dateofservicecall",
    "mts_crewcount",
    "mts_traveltimeonewayhours",
    "mts_totaltimeonsitehours",
  ];

  // Handlers
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChange = async (e) => {
    e.preventDefault();
    console.log(e.target.type);
    if (e.target.type === "file") {
      let base = await convertBase64(e.target.files[0]);
      console.log(base);
      let valid = base.split(",")[1];
      stateSetter((prev) => {
        return {
          ...prev,
          [e.target.id]: valid
        };
      });
    } else {
      stateSetter((prev) => {
        if (e.target.type === "number") {
          return {
            ...prev,
            [e.target.id]: parseInt(e.target.value)
          }
        }
        if (e.target.type === "date") {
          return {
            ...prev,
            [e.target.id]: dayjs(e.target.value).format("YYYY-MM-DD")
          }
        }
        if (e.target.type.includes("date")) {
          console.log(new Date(e.target.value).toUTCString())
          console.log(dayjs.utc(e.target.value).format())
          return {
            ...prev,
            [e.target.id]: new Date(e.target.value).toUTCString()
          }
        }
        return {
          ...prev,
          [e.target.id]: e.target.value,
        };
      });
    }
  };

  if (type === "date") {
    return (
      <article className="input-wrapper">
        <label htmlFor={id}>
          {title} {required && <Asterisk />}
        </label>
        <input
          autocomplete="off"
          onChange={handleChange}
          value={formName[id]}
          id={id}
          type="date"
        />
      </article>
    );
  }

  if (requiredFields.includes(id) && type === "number") {
    return (
      <article className="input-wrapper">
        <label htmlFor={id}>
          {title} {required && <Asterisk />}
        </label>
        <input
          autocomplete="off"
          min="0"
          required
          onChange={handleChange}
          value={formName[id]}
          id={id}
          type="number"
        />
      </article>
    );
  }

  if (requiredFields.includes(id)) {
    return (
      <article className="input-wrapper">
        <label htmlFor={id}>
          {title} {required && <Asterisk />}
        </label>
        <input
          autocomplete="off"
          required
          onChange={handleChange}
          value={formName[id]}
          id={id}
        />
      </article>
    );
  }

  if (type === "number") {
    return (
      <article className="input-wrapper">
        <label htmlFor={id}>
          {title} {required && <Asterisk />}
        </label>
        <input
          autocomplete="off"
          onChange={handleChange}
          value={formName[id]}
          id={id}
          type="number"
          min={id === 'mts_crewcount' ? "1" : "0"}
          secureTextEntry={safe}
        />
      </article>
    );
  }

  if (type === "textarea") {
    return (
      <article className="input-wrapper">
        <label htmlFor={id}>
          {title} {required && <Asterisk />}
        </label>
        <textarea onChange={handleChange} value={formName[id]} id={id} />
      </article>
    );
  }

  if (type === "file") {
    return (
      <article className="file-input">
        <label htmlFor={id} style={{ marginTop: 15 }}>
          {title} {required && <Asterisk />}
        </label>
        <input onChange={handleChange} type="file" id={id} accept="image/*" />
      </article>
    );
  }

  if (type === "datetime") {
    return (
      <article className="input-wrapper">
        <label htmlFor={id}>
          {title} {required && <Asterisk />}
        </label>
        <input
          autocomplete="off"
          onChange={handleChange}
          value={formName[id]}
          id={id}
          type="datetime-local"
        />
      </article>
    );
  }

  if (type === "multi-input" && formName === "foremanChecklistFormData") {
    return (
      <>
        <label htmlFor={id}>{title}</label>
        <article className="multi-input-wrapper">
          <section className="multi-input-container">
            <label style={{ fontWeight: "normal" }}>A1</label>
            <input
              autocomplete="off"
              className="multi-input"
              onChange={handleChange}
              id={"mts_a1"}
            />
          </section>
          <section className="multi-input-container">
            <label style={{ fontWeight: "normal" }}>A2</label>
            <input
              autocomplete="off"
              className="multi-input"
              onChange={handleChange}
              id={"mts_a2"}
            />
          </section>
          <section className="multi-input-container">
            <label style={{ fontWeight: "normal" }}>A3</label>
            <input
              autocomplete="off"
              className="multi-input"
              onChange={handleChange}
              id={"mts_a3"}
            />
          </section>
          <section className="multi-input-container">
            <label style={{ fontWeight: "normal" }}>A4</label>
            <input
              autocomplete="off"
              className="multi-input"
              onChange={handleChange}
              id={"mts_a4"}
            />
          </section>
          <section className="multi-input-container">
            <label style={{ fontWeight: "normal" }}>A5</label>
            <input
              autocomplete="off"
              className="multi-input"
              onChange={handleChange}
              id={"mts_a5"}
            />
          </section>
          <section className="multi-input-container">
            <label style={{ fontWeight: "normal" }}>A6</label>
            <input
              autocomplete="off"
              className="multi-input"
              onChange={handleChange}
              id={"mts_a6"}
            />
          </section>
        </article>
      </>
    );
  }

  return (
    <article className="input-wrapper">
      <label htmlFor={id}>
        {title} {required && <Asterisk />}
      </label>
      <input
        autocomplete="off"
        onChange={handleChange}
        value={formName[id]}
        id={id}
      />
    </article>
  );
}
