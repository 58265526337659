import React from 'react'

export default function LargeFileUpload({stateSetter, id}) {
  

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const handleChange = async e => {
    e.preventDefault()
    console.log(e.target.files[0].type.replace(/(.*)\//g, ''))
    console.log(e.target.files[0].name)

    let base =await convertBase64(e.target.files[0])
    console.log(base)
    let valid = base.split(",")[1]

    const fileObject = {
      imageName: e.target.files[0].name,
      documentBody: valid,
      fileType: e.target.files[0].type.replace(/(.*)\//g, '')
    }

    stateSetter(prev => ([...prev, fileObject]));
  }

  return (
    <>
    <label>{id}</label>
    <input id={`${id}`} title={`${id}`} key={id} type='file' onChange={handleChange}/>
    </>
  )
}
