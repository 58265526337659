import React, { useContext, useEffect } from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./authConfig";
import { AuthContext } from "./AuthContext";

const AzureAuth = ({ children, ...props }) => {
  const {
    setAuthProviderLocal,
    setLogout,
    setAccount,
    setAuthenticated,
    isAuthenticated,
    setAccess,
    setJWT,
    jwt,
    access,
    setAzureAccount,
  } = useContext(AuthContext);

  const authSet = (bool) => {
    setAuthenticated(bool);
  };
  return (
    <AzureAD provider={authProvider}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        //run call
        // const logoutWrapped = logout;
        const wrappedLogin = () => {
          return login;
        };
        // setLogout(logoutWrapped);
        setAuthProviderLocal(wrappedLogin);
        //run my auth

        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            console.log('authenticted')
            setAzureAccount(accountInfo);
            authSet(true);
            return children;
          case AuthenticationState.Unauthenticated:
            authSet(false);
            // return <p>not logged in</p>;
            return children;
          case AuthenticationState.InProgress:
            // setAuthenticated(true);
            return <p>Authenticating...</p>;
          default:
            return <p>Something happened</p>;
        }
      }}
    </AzureAD>
  );
};

export default AzureAuth;
