import React from 'react';
import './MomentumHeader.css';
import momentumlogo from './momentumlogo.svg';

export default function MomentumHeader({title}) {
  return (
    <header className="momentum-header">
      <img src={momentumlogo} alt="momentum solar" className="company-logo"/>
    </header>
  )
}
