import React, { useContext } from "react";
import { Context } from "../App";
import { newForm, getUserInfo } from "../API/localAPI";

export default function ProjectSearch({ endpoint, idForPhoto }) {
  const {
    foundProject,
    setFoundProject,
    projNumber,
    setProjNumber,
    setError,
    setDidSearch,
    setUnixVal,
    setSubmissionId,
    setValid,
    setLoading,
    setProjectNumVal,
  } = useContext(Context);

  const clickedYes = (e) => {
    e.preventDefault();
    // console.log(projNumber)
    setLoading(true);

    newForm(projNumber, endpoint)
      .then((v) => {
        setDidSearch(true);
        setLoading(false);
        setValid("Project Accessed");
        setUnixVal(v.unix);
        setSubmissionId(v[idForPhoto]);
        console.log(v[idForPhoto]);
      })
      .catch((e) => {
        setLoading(false);
        alert(`Please try again. Error: ${e}`);
        console.log(e);
      });
  };
  const clickedNo = (e) => {
    e.preventDefault();
    setDidSearch(false);
    setProjNumber("");
    setFoundProject({});
  };

  const searchClick = async (e) => {
    e.preventDefault();
    if (projNumber === "") {
      setError("make sure to type a project number");
    } else {
      let found = await getUserInfo(projNumber);
      console.log(found);
      if (found.length > 0) {
        setFoundProject(found[0]);
      } else {
        setProjNumber("");
        setError("No project found. Try again.");
      }
    }
  };

  return (
    <div
      className="initial_search_div"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
        borderRadius: "5px",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "20px",
      }}
    >
      {Object.keys(foundProject).length === 0 && (
        <>
          <input
            style={{
              margin: 10,
              marginTop: "1em",
              marginBottom: "1em",
              width: "200px",
              padding: "1em",
            }}
            value={projNumber}
            onChange={(e) => setProjNumber(e.currentTarget.value)}
            placeholder="search for a project number"
          />
          <button
            style={{
              padding: "0.5em",
              marginBottom: "0.5em",
              border: "none",
              borderRadius: "7px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
              color: "white",
              fontSize: "0.9375rem",
              fontWeight: "600",
              backgroundColor: "rgb(24 119 242)",
            }}
            onClick={searchClick}
          >
            search
          </button>
        </>
      )}
      {Object.keys(foundProject).length > 0 && (
        <>
          <p style={{ margin: 10, fontWeight: "bold" }}>
            Is this the correct customer?
          </p>
          <div
            style={{
              margin: 10,
              background: "#add8e6",
              margin: 5,
              padding: "0.7em",
              marginTop: "0.5em",
              marginBottom: "0.5em",
              borderRadius: "8px",
            }}
          >
            <p>{foundProject.iis_householdid.name}</p>
            <p>{`${foundProject.iis_householdid.address1_line1}, ${foundProject.iis_householdid.address1_city}, ${foundProject.iis_householdid["iis_state@OData.Community.Display.V1.FormattedValue"]}`}</p>
            {/* <p>{foundProject.iis_householdid.telephone2}</p> */}

            <button
              onClick={clickedYes}
              style={{
                width: "100px",
                padding: "0.5em",
                marginRight: "0.5em",
                marginTop: "0.5em",
                border: "none",
                borderRadius: "7px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                color: "white",
                fontSize: "0.9375rem",
                fontWeight: "600",
                backgroundColor: "#25d366",
              }}
            >
              Yes
            </button>
            <button
              onClick={clickedNo}
              style={{
                width: "100px",
                padding: "0.5em",
                border: "none",
                borderRadius: "7px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                color: "white",
                fontSize: "0.9375rem",
                fontWeight: "600",
                backgroundColor: "#FF5A5F",
              }}
            >
              No
            </button>
          </div>
        </>
      )}
    </div>
  );
}
