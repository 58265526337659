export const gatherAdditionalImages = (body) => {
  const final = [];
  console.log('gathering images')

  Object.keys(body).forEach(key => {
    if (key.includes('photo_')) {
      final.push({
        documentBody: body[key],
        imageName: key
      })
    }
  })

  console.log(final);
  return final;
}