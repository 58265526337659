import './Multiselect.css'
import React, { useState, useContext, useEffect } from 'react'


export default function Multiselect({formName, stateSetter, id, title, options}) {

  const handleChange = e => {
    if (e.target.checked) {
      stateSetter(prev => {
        return {
          ...prev,
          [e.target.name]: [...prev[e.target.name], e.target.value]
        }
      })
    } else {
      // const index = formName[e.target.name].indexOf(e.target.value);
      // console.log(index);

      stateSetter(prev => {
        return {
          ...prev,
          [e.target.name]: [...prev[e.target.name].filter(el => el !== e.target.value)]
        }
      })
    }

  }

  const handleShingleInput = e => {
    stateSetter(prev => {
      return {
        ...prev,
        mts_numberofshinglelayers: e.target.value
      }
    })
  }

  const checkboxes = options.map(element => {
    return (
      <article key={element.value} className='checkbox-row'>
        <input className="shadow checkbox" onChange={handleChange} value={element.value} type="checkbox" name={id} checked={formName[id]} />
        <label className="multi-label" htmlFor={element}>{element.name}</label>
      </article>
    )
  })

  return (
    <div className="multiselect-area"> 
      <p style={{fontWeight:"bold"}}className="multiselect-title">{title}:</p>
      <section className='checkbox-wrapper'>
        {checkboxes}
      </section>
      {title === "Existing Roof Material" && (
        <>
          <label style={{fontWeight:"bold"}} id="mts_numberofshinglelayers-label" htmlFor="mts_numberofshinglelayers"># of Shingle Layers:</label>
          <input id="mts_numberofshinglelayers" type="number" onChange={handleShingleInput} value={formName.mts_numberofshinglelayers} />
        </>
      )}
    </div>
  )
}
