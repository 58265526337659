// import './Form.css';
import React, { useState, useContext, useEffect } from "react";
import Dropdown from "../../../AppComponents/Dropdown/Dropdown";
import Multiselect from "../../../AppComponents/Multiselect/Multiselect";
import Input from "../../../AppComponents/Input/Input";
import { Context } from "../../../App";
import { useParams } from "react-router-dom";
import Asterisk from "../../../Styling/Asterisk";
import { gatherAdditionalImages } from "../../../util";
import Button from "../../../AppComponents/Button/Button";
import FormSection from "../../../AppComponents/FormSection/FormSection";

const {
  getUserInfo,
  newRoofLeak,
  patchRoofLeak,
  postLargeFiles,
  retrieveRoofLeak,
} = require("../../../API/localAPI");

export default function Form() {
  // State
  const {
    roofLeaksFormData,
    setRoofLeaksFormData,
    setValid,
    setError,
    loading,
    setLoading,
  } = useContext(Context);
  let { id } = useParams();
  const [didSearch, setDidSearch] = useState(false);
  const [projNumber, setProjNumber] = useState("");
  const [foundProject, setFoundProject] = useState({});
  const [formKeys, setFormKeys] = useState();

  // Get Case Num from URL
  // useEffect(() => {

  //   setRoofLeaksFormData(prev => {
  //     return ({
  //       ...prev,
  //       mts_casenumber: id
  //     })
  //   })
  // }, [])
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Handlers
  const handleChange = async (e) => {
    e.preventDefault();
    // console.log(e.target.type);
    if (e.target.type === "file") {
      let base = await convertBase64(e.target.files[0]);
      console.log(base);
      let valid = base.split(",")[1];
      setRoofLeaksFormData((prev) => {
        return {
          ...prev,
          [e.target.id]: valid,
        };
      });
    } else {
      setRoofLeaksFormData((prev) => {
        return {
          ...prev,
          [e.target.id]: e.target.value,
        };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(projNumber);
    // console.log(foundProject);

    //retrieve roofleak id

    let finalData = roofLeaksFormData;
    // console.log(finalData);

    if (validate(finalData)) {
      // console.log('validated', finalData);
      // set data to correct keys and parse
      finalData.mts_leakorigin = finalData.mts_leakorigin.join(",");
      finalData.mts_rooftype = finalData.mts_rooftype.join(",");
      finalData.mts_existingroofmaterial =
        finalData.mts_existingroofmaterial.join(",");
      finalData.mts_roofrepaircost = +finalData.mts_roofrepaircost;
      finalData.mts_roofingcompany =
        finalData.mts_roofingcompany === "false" ? false : true;
      finalData.mts_numberofshinglelayers =
        +finalData.mts_numberofshinglelayers;
      // parse again?
      finalData = fixBody(finalData);

      // get roof leak id
      retrieveRoofLeak(formKeys.mts_projectnumbertext)
        .then((res) => {
          patchRoofLeak(foundProject.iis_projectid, res, finalData);

          // gather all images
          const constructImageObjects = (images) => {
            return images
              .filter((image) => {
                if (image.documentBody && image.documentBody.length > 0) {
                  // console.log('passed check', image.imageName);
                  return image;
                }
              })
              .map((image) => {
                // console.log('image for return', image)
                return image;
              });
          };

          const finalImages = [
            {
              documentBody: finalData.repairs_made_img,
              imageName: "repairs_made",
            },
            {
              documentBody: finalData.interior_leak_location_img,
              imageName: "interior_leak_location",
            },
            {
              documentBody: finalData.interior_damage_img,
              imageName: "interior_damage",
            },
            {
              documentBody: finalData.current_roof_condition_img,
              imageName: "current_roof_condition",
            },
            {
              documentBody: finalData.leak_origin_img,
              imageName: "leak_origin",
            },
            ...gatherAdditionalImages(roofLeaksFormData),
          ];

          // console.log('final images', constructImageObjects(finalImages));

          // post all images
          // console.log('posting large files');
          postLargeFiles(
            "objectid_mts_roofleaks@odata.bind",
            "mts_roofleakses",
            res,
            constructImageObjects(finalImages)
          );
          // postRoofLeakImages(res, finalData)
          // postAdditionalImages('roofleaksid', res, gatherAdditionalImages(roofLeaksFormData))
        })
        .then(() => {
          setLoading(false);
          setValid("Success");
        })
        .catch((err) => {
          setLoading(false);
          setError("Unsuccessful. Try again");
          alert(err);
        });

      // reset state
      setLoading(false);
      setFoundProject({});
      setProjNumber("");
      setDidSearch(false);
      setRoofLeaksFormData({
        mts_casetype: "962080000",
        mts_date: "",
        mts_arrivaltimeonsite: "",
        mts_departuretime: "",
        mts_currentweathercondition: "962080000",
        mts_rooftechnicianname: "",
        mts_roofingcompany: "In House",
        mts_roofcompanyifnotinhouse: "",
        mts_rooftype: [],
        mts_existingroofmaterial: [],
        mts_numberofshinglelayers: 0,
        mts_leakseverity: "962080000",
        mts_leakorigin: [],
        mts_roofrepaircost: "",
        mts_leakduetoerrorduringsolarinstallation: "962080000",
        leak_origin_img: "",
        current_roof_condition_img: "",
        interior_damage_img: "",
        interior_leak_location_img: "",
        repairs_made_img: "",
        mts_technotes: "",
        mts_customernotes: "",
      });
    } else {
      setLoading(false);
      setError("Something went wrong, check your fields and try again");
    }

    console.log(finalData);
  };

  const searchClick = async (e) => {
    e.preventDefault();
    if (projNumber === "") {
      setError("make sure to type a project number");
    } else {
      let found = await getUserInfo(projNumber);
      console.log(found);
      if (found.length > 0) {
        console.log("start");
        setFoundProject(found[0]);
      }
    }
  };

  const validate = (body) => {
    let detectedError = false;
    Object.keys(body).forEach((v) => {
      if (body[v] === "" || body[v] === []) {
        if (
          !v.includes("photo_") &&
          !v.includes("img") &&
          v !== "mts_roofcompanyifnotinhouse" &&
          v !== "mts_numberofshinglelayers" &&
          v !== "mts_roofrepaircost" &&
          v !== "mts_arrivaltimeonsite" &&
          v !== "mts_departuretime" &&
          v !== "mts_technotes" &&
          v !== "mts_customernotes"
        ) {
          console.log(v);
          detectedError = v;
        }
      }
    });
    if (detectedError) {
      setError(`Missing ${detectedError}`);
      return false;
    } else {
      return true;
    }
  };

  const fixBody = (body) => {
    for (var key in body) {
      if (body[key] === "") {
        delete body[key];
      }
    }
    return body;
  };

  const clickedYes = (e) => {
    e.preventDefault();
    console.log(projNumber);

    newRoofLeak(projNumber)
      .then((v) => {
        setDidSearch(true);
        setFormKeys(v);
      })
      .catch((e) => {
        setDidSearch(false);
        alert(`Please try again. Error: ${e}`);
        console.log(e);
      });
  };
  const clickedNo = (e) => {
    e.preventDefault();
    setDidSearch(false);
    setProjNumber("");
    setFoundProject({});
  };

  const fileInput = (id) => (
    <article className="file-input">
      <Input
        formName="roofLeaksFormData"
        title={id}
        stateSetter={setRoofLeaksFormData}
        onChange={handleChange}
        type="file"
      />
    </article>
  );
  // Construct additional photo inputs
  const additionalPhotoInputs = [];

  for (let i = 0; i < 15; i++) {
    additionalPhotoInputs.push(fileInput(`photo_${i + 1}`));
  }

  // Render
  return !didSearch ? (
    <div
      className="initial_search_div"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
        borderRadius: "5px",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {Object.keys(foundProject).length === 0 && (
        <>
          <input
            className="input_initial_search"
            style={{
              margin: 10,
              marginTop: "1em",
              marginBottom: "1em",
              width: "200px",
              padding: "1em",
            }}
            value={projNumber}
            onChange={(e) => setProjNumber(e.currentTarget.value)}
            placeholder="search for a project number"
          />
          <button
            style={{
              padding: "0.5em",
              marginBottom: "0.5em",
              border: "none",
              borderRadius: "7px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
              color: "white",
              fontSize: "0.9375rem",
              fontWeight: "600",
              backgroundColor: "rgb(24 119 242)",
            }}
            onClick={searchClick}
          >
            search
          </button>
        </>
      )}
      {Object.keys(foundProject).length > 0 && (
        <>
          <p style={{ marginTop: "0.5em", fontWeight: "bold" }}>
            Is this the correct customer?
          </p>
          <div
            style={{
              background: "#add8e6",
              padding: "0.7em",
              margin: 10,
              marginTop: "0.5em",
              marginBottom: "0.5em",
              borderRadius: "8px",
            }}
          >
            <p>{foundProject.iis_householdid.name}</p>
            <p>{`${foundProject.iis_householdid.address1_line1}, ${foundProject.iis_householdid.address1_city}, ${foundProject.iis_householdid["iis_state@OData.Community.Display.V1.FormattedValue"]}`}</p>
            {/* <p>{foundProject.iis_householdid.telephone2}</p> */}

            <button
              onClick={clickedYes}
              style={{
                width: "100px",
                padding: "0.5em",
                marginRight: "0.5em",
                marginTop: "0.5em",
                border: "none",
                borderRadius: "7px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                color: "white",
                fontSize: "0.9375rem",
                fontWeight: "600",
                backgroundColor: "#25d366",
              }}
            >
              Yes
            </button>
            <button
              onClick={clickedNo}
              style={{
                width: "100px",
                padding: "0.5em",
                border: "none",
                borderRadius: "7px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                color: "white",
                fontSize: "0.9375rem",
                fontWeight: "600",
                backgroundColor: "#FF5A5F",
              }}
            >
              No
            </button>
          </div>
        </>
      )}
    </div>
  ) : (
    <form className="roof-leak-form" onSubmit={handleSubmit}>
      <FormSection title="General Information">
        {/* <article >
              <label style={{fontWeight:"bold"}} htmlFor="mts_casenumber">Case Number:</label>
              <p>{id}</p>
            </article> */}
        <Dropdown
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          required
          id="mts_casetype"
          title="Case Type"
          options={[
            { name: "Roof Leak", value: "962080000" },
            { name: "Exterior Damage", value: "962080001" },
            { name: "Interior Damage", value: "962080002" },
          ]}
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Date"
          id="mts_date"
          type="date"
          required
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Arrival Time On Site"
          id="mts_arrivaltimeonsite"
          type="datetime"
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Departure Time"
          id="mts_departuretime"
          type="datetime"
        />
        <Dropdown
          required
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          id="mts_currentweathercondition"
          title="Current Weather Condition"
          options={[
            { name: "Sunny", value: "962080000" },
            { name: "Cloudy", value: "962080001" },
            { name: "Rain", value: "962080002" },
            { name: "Snow / Hail", value: "962080003" },
          ]}
        />
      </FormSection>
      <FormSection title="Leak Assessment">
        <Input
          required
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Roof Technician Name:"
          onChange={handleChange}
          value={roofLeaksFormData.mts_rooftechnicianname}
          id="mts_rooftechnicianname"
        />
        <Dropdown
          required
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          id="mts_roofingcompany"
          title="Roofing Company"
          options={[
            { name: "In House", value: true },
            { name: "Other", value: false },
          ]}
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Roofing Company (if not In House):"
          value={roofLeaksFormData.mts_roofcompanyifnotinhouse}
          id="mts_roofcompanyifnotinhouse"
        />
        <Multiselect
          required
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          id="mts_rooftype"
          title="Roof Type"
          options={[
            { name: "Flat", value: "962080000" },
            { name: "Low Slope", value: "962080001" },
            { name: "Pitched", value: "962080002" },
          ]}
        />
        <Multiselect
          required
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          id="mts_existingroofmaterial"
          title="Existing Roof Material"
          options={[
            { name: "Heat Applied (Torch Down)", value: "962080000" },
            { name: "Concrete Tiles", value: "962080001" },
            { name: "Metal", value: "962080002" },
            { name: "Silicone System", value: "962080003" },
            { name: "TPO", value: "962080004" },
            { name: "MOD BID", value: "962080005" },
            { name: "Mule Hide (Single-Ply)", value: "962080006" },
            { name: "Shingles (# of Layers Below)", value: "962080007" },
          ]}
        />
        <Dropdown
          required
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          id="mts_leakseverity"
          title="Leak Severity"
          options={[
            { name: "Evidence of Leak - spots/stains", value: "962080000" },
            { name: "Dripping - Buckets Required", value: "962080001" },
            { name: "Pouring - Tarp required over roof", value: "962080002" },
            { name: "NO EVIDENCE OF LEAK", value: "962080003" },
          ]}
        />
        <Multiselect
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          id="mts_leakorigin"
          title="Leak Origin"
          options={[
            { name: "Missed Rafter Lag", value: "962080000" },
            { name: "Unsealed Pilot Hole", value: "962080001" },
            { name: "Roof Boot", value: "962080002" },
            { name: "Ridge Cap", value: "962080003" },
            { name: "Shingle Torn/Unsealed", value: "962080004" },
            { name: "Skylight", value: "962080005" },
            { name: "Valley", value: "962080006" },
            { name: "Chimney", value: "962080007" },
            { name: "Base/Flashing/EDPM Washer", value: "962080008" },
            { name: "Unsealed Nail Pop", value: "962080009" },
          ]}
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Roof Repair Cost ($XX.XX):"
          value={roofLeaksFormData.mts_roofrepaircost}
          id="mts_roofrepaircost"
          type="number"
        />
        <Dropdown
          required
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          id="mts_leakduetoerrorduringsolarinstallation"
          title="Is this leak due to an error during solar installation?"
          options={[
            { name: "Yes", value: "962080000" },
            { name: "Yes plus additional issues", value: "962080001" },
            { name: "No", value: "962080002" },
          ]}
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Tech Notes:"
          id="mts_technotes"
          type="textarea"
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Customer Notes:"
          id="mts_customernotes"
          type="textarea"
        />
      </FormSection>
      <FormSection title="Assessment Photos">
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Leak Origin:"
          id="leak_origin_img"
          type="file"
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Current Roof Condition:"
          id="current_roof_condition_img"
          type="file"
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Interior Damage:"
          id="interior_damage_img"
          type="file"
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Interior Leak Location:"
          id="interior_leak_location_img"
          type="file"
        />
        <Input
          formName="roofLeaksFormData"
          stateSetter={setRoofLeaksFormData}
          title="Repairs Made:"
          id="repairs_made_img"
          type="file"
        />
        {additionalPhotoInputs}
      </FormSection>
      <Button onClick={handleSubmit} type={"submit"} title={"Submit"} />
    </form>
  );
}
