import { unix } from 'dayjs'

const axios = require('axios')
const dayjs = require('dayjs')

export const getOffices = () => {
  return new Promise(async (resolve, reject) => {
    const data = {
      query: `mts_momentumoffices?$select=mts_momentumofficeid,mts_name`,
    }
    try {
      let final = await axios.post(
        'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/universalcrm',
        data
      )
      // console.log(final.data.value)
      resolve(final.data.value)
    } catch (e) {
      reject(e)
      console.log(e)
      console.log('err')
    }
  })
}

export const newRoof = (projNumber) => {
  return new Promise(async (resolve, reject) => {
    let num = projNumber.replace(/\D/g, '')
    console.log(num)
    const data = { mts_projectnumber: `MS${num}` }
    console.log(data)
    try {
      let final = await axios.post(
        `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/new_roof_sales`,
        data
      )
      console.log(final)
      console.log(final.data)
      resolve(final.data)
    } catch (e) {
      console.log(e)
      console.log('err with new service request')
      reject(e)
    }
  })
}

export const getUserInfo = (projNum) => {
  return new Promise(async (resolve, reject) => {
    let num = projNum.replace(/\D/g, '')
    console.log(num)
    let string = `iis_projects?$top=1&$select=velosio_projectnumber&$expand=iis_householdid($select=iis_state,address1_line1,address1_postalcode,telephone2,emailaddress1,address1_city,name)&$filter=(velosio_projectnumber eq 'MS${num}')`

    console.log(string)
    const data = {
      query: string,
    }
    try {
      let final = await axios.post(
        'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/universalcrm',
        data
      )
      resolve(final.data.value)
    } catch (e) {
      reject(e)
      console.log(e)
      console.log('err')
    }
  })
}

export const newRoofLeak = (projNumber) => {
  return new Promise(async (resolve, reject) => {
    let num = projNumber.replace(/\D/g, '')
    let now = dayjs().unix();
    const data = { mts_projectnumbertext: `MS${num}_${now}` }
    try {
      let final = await axios.post(
        `https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/newroofleak`,
        data
      )
      resolve({
        mts_projectnumbertext: `MS${num}_${now}`
      })
      
    } catch (e) {
      console.log(e)
      console.log('err')
      reject(e)
    }
  })
}

export const retrieveRoofLeak = (projNumber) => {
  return new Promise(async (resolve, reject) => {
    let string = `mts_roofleakses?$filter=mts_projectnumbertext eq '${projNumber}'`
    // console.log(string)
    const data = {
      query: string,
    }
    try {
      let final = await axios.post(
        'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/universalcrm',
        data
      )
      console.log(final.data.value)
      resolve(final.data.value[0].mts_roofleaksid)
    } catch (e) {
      reject(e)
      console.log(e)
      console.log('err')
    }
  })
}
export const patchRoofSales = (project_value, unix, projectNumber, body) => {
  return new Promise(async (resolve, reject) => {
    let updatedBody = { ...body }

    delete updatedBody.contract
    delete updatedBody.upload_2
    delete updatedBody.upload_3
    delete updatedBody.upload_4
    delete updatedBody.upload_5

    const data = {
      _mts_projectnumber_value: project_value,
      unix: unix,
      mts_projectnumber: projectNumber,
      ...updatedBody,
    }
    try {
      console.log(data)

      let final = await axios.post(
        'https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/update_solar_roof_sales',
        data
      )
      resolve(data)
    } catch (e) {
      console.log(e)
      console.log('err')
      reject(e)
    }
  })
}

export const patchRoofLeak = (project_value, roofleaksid, body) => {
  return new Promise(async (resolve, reject) => {
    let updatedBody = { ...body }
    delete updatedBody.roof_repaid_cost_reciept_img
    delete updatedBody.repairs_made_img
    delete updatedBody.interior_leak_location_img
    delete updatedBody.interior_damage_img
    delete updatedBody.current_roof_condition_img
    delete updatedBody.additional_repairs_needed_img
    delete updatedBody.roof_repair_cost_reciept_img
    delete updatedBody.leak_origin_img
    const data = {
      _mts_project_value: project_value,
      mts_roofleaksid: roofleaksid,
      ...updatedBody,
    }
    try {
      console.log(data)

      let final = await axios.post(
        'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/updateroofleak',
        data
      )
      resolve(final)
    } catch (e) {
      reject(e)
    }
  })
}
export const getRoofSalesEntry = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let final = await axios.get(
        `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/solar_roof_sale_form_entry`
      )
      resolve(final.data)
    } catch (e) {
      reject(e)
      console.log('error on load')
    }
  })
}



export const getServiceRequestDropdowns = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let serviceRequestDropdowns = await axios.get(
        'https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/service_form_entry'
      )
      resolve(serviceRequestDropdowns)
    } catch (error) {
      reject(error)
      console.log(error)
    }
  })
}

export const getServiceRequestEmployees = () => {
  return new Promise(async (resolve, reject) => {
    const data = {
      query:
        "contacts?$select=fullname,contactid,address1_city&$filter=(Microsoft.Dynamics.CRM.In(PropertyName='accountrolecode',PropertyValues=['911890001','100000000']) and statecode eq 0)&$orderby=fullname asc",
    }
    try {
      let serviceRequestEmployees = await axios.post(
        'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/universalcrm',
        data
      )

      resolve(serviceRequestEmployees)
    } catch (error) {
      reject(error)
      console.log(error)
    }
  })
}

export const newServiceRequest = (projNumber) => {
  return new Promise(async (resolve, reject) => {
    let num = projNumber.replace(/\D/g, '')
    const data = { mts_projectnumber: `MS${num}` }
    try {
      let final = await axios.post(
        `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/new_empty_service`,
        data
      )
      resolve(final.data)
    } catch (e) {
      console.log(e)
      console.log('err with new service request')
      reject(e)
    }
  })
}

export const updateServiceRequest = (unixVal, projNum, projID, body) => {
  return new Promise(async (resolve, reject) => {
    let updatedBody = { ...body }
    delete updatedBody.img_before_service
    delete updatedBody.img_after_service
    delete updatedBody.img_envoy_green_light
    delete updatedBody.img_micro_confirmation

    for (const property in updatedBody) {
      if (updatedBody[property] === '') {
        delete updatedBody[property]
      }
    }

    const data = {
      unix: unixVal,
      mts_projectnumber: projNum,
      _mts_projectnumber_value: projID,
      ...updatedBody,
    }

    try {
      let final = await axios.post(
        'https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/update_service',
        data
      )
      console.log(final)
      resolve()
    } catch (e) {
      reject(e)
      console.log(e)
      console.log('err with service request update')
    }
  })
}

export const getInspectionsTechnicians = () => {
  return new Promise(async (resolve, reject) => {
    const data = {
      query:
        "contacts?$select=fullname,contactid,address1_city&$filter=(Microsoft.Dynamics.CRM.In(PropertyName='accountrolecode',PropertyValues=['911890001','100000000']) and statecode eq 0)&$orderby=fullname asc",
    }
    try {
      let serviceRequestEmployees = await axios.post(
        'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/universalcrm',
        data
      )

      resolve(serviceRequestEmployees)
    } catch (error) {
      reject(error)
      console.log(error)
    }
  })
}

export const getAllStates = () => {
  return new Promise(async (resolve, reject) => {
    const data = {
      query: 'mts_states?$select=mts_name,mts_stateid',
    }
    try {
      let allStates = await axios.post(
        'https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/universalcrm',
        data
      )

      resolve(allStates)
    } catch (error) {
      reject(error)
      console.log(error)
    }
  })
}

export const newForm = (projNumber, endpoint) => {
  return new Promise(async (resolve, reject) => {
    let num = projNumber.replace(/\D/g, '')
    const data = { mts_projectnumber: `${num}` }
    try {
      let final = await axios.post(
        `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/${endpoint}`,
        data
      )
      resolve(final.data)
    } catch (e) {
      console.log(e)
      console.log(`err with ${endpoint}`)
      reject(e)
    }
  })
}

export const newInspectionSubmission = (projNumber) => {
  return newForm(projNumber, 'new_inspection_submission')
}

export const updateInspectionSubmission = (unixVal, projNum, projID, body) => {
  return new Promise(async (resolve, reject) => {
    let updatedBody = { ...body }
    delete updatedBody.img_before_service
    delete updatedBody.img_after_service
    delete updatedBody.img_envoy_green_light
    delete updatedBody.img_micro_confirmation

    for (const property in updatedBody) {
      if (updatedBody[property] === '') {
        delete updatedBody[property]
      }
    }

    const data = {
      unix: unixVal,
      mts_projectnumber: projNum,
      _mts_projectnumber_value: projID,
      ...updatedBody,
    }

    try {
      let final = await axios.post(
        'https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/update_inspection_submission',
        data
      )
      resolve()
    } catch (e) {
      console.log(e)
      console.log('err with inspections update')
      reject(e)
    }
  })
}

export const updateForemanChecklist = (unixVal, projNum, projID, body) => {
  return new Promise(async (resolve, reject) => {
    let updatedBody = { ...body }

    for (const property in updatedBody) {
      if (updatedBody[property] === '' || updatedBody[property].length === 0) {
        delete updatedBody[property]
      }
    }

    const data = {
      unix: unixVal,
      mts_projectnumber: projNum,
      _mts_projectnumber_value: projID,
      ...updatedBody,
    }

    console.log(data)

    try {
      let final = await axios.post(
        'https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/update_foreman_checklist',
        data
      )
      resolve()
    } catch (e) {
      reject(e)
      console.log(e)
      console.log('err with inspections update')
    }
  })
}

export const updateFieldSupervisionChecklist = (
  unixVal,
  projNum,
  projVal,
  body
) => {
  return new Promise(async (resolve, reject) => {
    let updatedBody = { ...body }

    for (const property in updatedBody) {
      if (
        updatedBody[property] === '' ||
        updatedBody[property].length === 0 ||
        property.includes('img')
      ) {
        delete updatedBody[property]
      }
    }

    const data = {
      unix: unixVal,
      mts_projectnumber: projNum,
      _mts_projectnumber_value: projVal,
      ...updatedBody,
    }

    console.log(data)

    try {
      let final = await axios.post(
        'https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/update_field_supervision_checklist',
        data
      )
      resolve()
    } catch (e) {
      reject(e)
      console.log(e)
      console.log('err with field inspection update')
    }
  })
}

export const postLargeFiles = (odataBind, crmKey, id, images) => {
  return new Promise(async (resolve, reject) => {
    //all for token retrieval
    try {
      const token = await axios.get(
        `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/forms_get_token`
      )
      console.log(token.data)
      //---------------------------------------------

      //image uploading directly to crm
      try {
        console.log('IMAGES UPLOAD COMMENCING', images)
        images.forEach((image) => {
          let imageBody = {
            filename: image.imageName,
            documentbody: image.documentBody,
            subject: image.imageName,
          }
          imageBody[odataBind] = `/${crmKey}(${id})`
          let crmUrl =
            'https://procustomsolarllc755.crm.dynamics.com/api/data/v9.2/annotations'
          let imageConfig = {
            headers: {
              'OData-Version': '4.0',
              'OData-MaxVersion': '4.0',
              'Content-Type': 'application/json; charset=utf-8',
              Accept: 'application/json,*/*',
              Prefer:
                'odata.maxpagesize=500,odata.include-annotations=OData.Community.Display.V1.FormattedValue',
              Authorization: token.data,
            },
          }
          axios
            .post(crmUrl, imageBody, imageConfig)
            .then((res) => {
              console.log(res)
            })
            .catch((e) => {
              reject(e)
            })
        })
        resolve()
      } catch (e) {
        reject(e)
        console.log(e)
      }
    } catch (e) {
      reject('no token')
    }
  })
}

export const getRoofingSalesJobs = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const jobs = await axios.post('https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/roofing_sales_jobs', {email: email})

      resolve(jobs.data)
    } catch (e) {
      reject(e)
    }
  })
}