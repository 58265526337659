import styled from "styled-components";

const RoofSalesStyle = styled.div`
  background: white;
  margin-top: 1em;
  padding: 1em;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  max-width: 90vw;
`;
const FormCard = styled.div`
  background: white;
  margin-top: 1em;
  max-width: 90vw;
  padding: 10px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  max-width: 90vw;
  .space_form {
    margin: 0.2em;
  }
  .formcardheader {
    border: none;
    border-radius: 5px;
    color: white;
    background: #336699;
    padding: 0.3em;
  }

  .roof-sales-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  div.one_column {
    grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );

  }
  div.two_column {
    grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );

  }
  div.three_column {
    grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );

  }
`;

export { RoofSalesStyle, FormCard };
