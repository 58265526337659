import React from 'react';
import './Loading.css';

export default function Loading() {
  return (
    <article className='loader'>
      Loading...
    </article>
  )
}
