import React, { useState, useContext } from "react";
import "./Form.css";
import { Context } from "../../App";
import { Redirect } from "react-router-dom";
import Input from "../../AppComponents/Input/Input";
import Dropdown from "../../AppComponents/Dropdown/Dropdown";
import axios from "axios";
import { AuthContext } from "../../Auth/AuthContext";
import FormSection from "../../AppComponents/FormSection/FormSection";
import Button from "../../AppComponents/Button/Button";

export default function Form() {
  // State
  const {
    retentionExperianPullsPostBody,
    setRetentionExperianPullsPostBody,
    experianPullResult,
    setExperianPullResult,
    creditResultOpen,
    setCreditResultOpen,
    loading,
    setLoading,
    setError,
    setValid,
  } = useContext(Context);
  const { azureAccount } = useContext(AuthContext);

  const [consent, setConsent] = useState(false);
  const [demoId, setDemoId] = useState("");

  const allowedUsers = [
    "aking@momentumsolar.com",
    "jgardella@momentumsolar.com",
    "opena@momentumsolar.com",
    "mshrum@momentumsolar.com",
    "sschwartz@momentumsolar.com",
    "calvinnguyen@momentumsolar.com",
    "mdean@momentumsolar.com",
    "mbharrat@momentumsolar.com",
  ];

  // Handlers
  const handleConsentChange = (e) => {
    setConsent(!consent);
    console.log(azureAccount.account.userName);
  };

  const postMSESUpdate = async (pullResult, oppnum) => {
    const fullName = `${pullResult.mts_firstname.toUpperCase()} ${pullResult.mts_lastname.toUpperCase()}`;
    const mses = pullResult.mts_ntpstatus;
    const experianConsumerAccountId = pullResult.mts_expaccountidid;

    try {
      const demoAppt = await axios.post(
        "https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/universalcrm",
        {
          query: `mts_demoappointments?$top=1&$filter=mts_opportunitynumber eq '${oppnum}'`,
        }
      );

      const updateBody = {
        mses: mses,
        demoid: demoAppt.data.value[0].mts_demoappointmentid,
      };
      const ntpBody = {
        experianconsumeraccountid: experianConsumerAccountId,
        oppid: demoAppt.data.value[0].mts_demoappointmentid,
        name: fullName,
      };

      const msesPost = await axios.post(
        "https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/updatemses",
        updateBody
      );
      const ntpPost = await axios.post(
        "https://hs44lw4phi.execute-api.us-east-1.amazonaws.com/api/createntp",
        ntpBody
      );

      console.log("msesPost", msesPost);
      console.log("ntpPost", ntpPost);
    } catch (e) {
      console.log(e);
      setError("Error updating CRM: ", e);
      setLoading(false);
    }
  };

  const handleNoExperianData = () => {
    setLoading(false);
    setExperianPullResult({mts_creditscore: "N/A", mts_msescreditnotes: "No record found in Experian"});
    setCreditResultOpen(true);
    setError("No record found in Experian");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let cleanedBody = retentionExperianPullsPostBody;

    // check fields before submit
    if (cleanedBody.oppnum.slice(0, 3) !== "OP-") {
      setLoading(false);
      return alert("Incorrect Formatting: Opp Number");
    }

    // this might be broken
    if (cleanedBody.bday.slice(4, 5) === "-") {
      setLoading(false);
      cleanedBody.bday = `${cleanedBody.bday.slice(
        5,
        7
      )}-${cleanedBody.bday.slice(8, 10)}-${cleanedBody.bday.slice(0, 4)}`;
    }

    if (cleanedBody.state.length !== 2) {
      setLoading(false);
      return alert("Incorrect Formatting: State must be abbreviated (ex. NY)");
    }
    cleanedBody.state = cleanedBody.state.toUpperCase();

    if (cleanedBody.zip.length !== 5) {
      setLoading(false);
      return alert("Incorrect Formatting: Zipcode must be five digits");
    }

    console.log("cleanedBody", cleanedBody);

    const exampleBody = {
      fname: "Jonathan",
      lname: "Consumer",
      mname: "R",
      oppnum: "OP-00000",
      repname: "Rayen Kamta",
      bday: "09-09-1999",
      street: "10665 birch",
      additionalstreet: "Unit 123C",
      city: "Burbank",
      state: "CA",
      zip: "34270",
      social: "666634374",
    };

    try {
      const existing = await axios.post(
        "https://hs44lw4phi.execute-api.us-east-1.amazonaws.com/api/checkexistingreport",
        { oppnum: cleanedBody.oppnum }
      );
      if (existing.data.value[0]) {
        console.log("did not pull credit");
        const parsed = existing.data.value[0];
        setExperianPullResult(parsed);
        postMSESUpdate(parsed, cleanedBody.oppnum);

        // go to result page and clear state
        setLoading(false);
        setValid("Found previous credit pull!");
        setCreditResultOpen(true);
        setConsent(false);
      } else {
        console.log("no existing report. sending new");
        const softPull = await axios.post(
          "https://hs44lw4phi.execute-api.us-east-1.amazonaws.com/api/softpull",
          cleanedBody
        );
        // MAYBE HERE?
        console.log("softPull", softPull);

          const newCreditReport = await axios.post(
            "https://hs44lw4phi.execute-api.us-east-1.amazonaws.com/api/checkexistingreport",
            { oppnum: cleanedBody.oppnum }
          );
          console.log("newCreditReport", newCreditReport);
          if (!newCreditReport.data) {
            handleNoExperianData();
          } else {
          // if soft pull posted correctly
          if (newCreditReport.data.value[0]) {
            const parsed = newCreditReport.data.value[0];
            setExperianPullResult(parsed);
            postMSESUpdate(parsed, cleanedBody.oppnum);
            setCreditResultOpen(true);
            setValid("Success");
          } else {
            setError("Unsuccessful. Please Try Again");
          }
          setLoading(false);
          setConsent(false);
        }
      }
    } catch (e) {
      setLoading(false);
      setError("Unsuccessful. Try Again");
      alert("Submit Error: ", e);
    }
  };

  if (allowedUsers.includes(azureAccount.account.userName)) {
    return creditResultOpen ? (
      <Redirect to="/retention_experian_pulls/result" />
    ) : (
      <form onSubmit={handleSubmit}>
        <FormSection title="Opportunity Information">
          <article className="retention-horizontal">
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="oppnum"
              title="Opportunity Number (ex. OP-12345)"
              required
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="repname"
              title="Rep Name"
              required
            />
          </article>
        </FormSection>
        <FormSection title="Customer Information">
          <article className="retention-horizontal">
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="fname"
              title="First Name"
              required
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="mname"
              title="Middle Initial"
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="lname"
              title="Last Name"
              required
            />
            <Dropdown
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="generationCode"
              title="Generation"
              options={[
                { name: "", value: "" },
                { name: "Jr.", value: "JR" },
                { name: "Sr.", value: "SR" },
                { name: "I", value: "I" },
                { name: "II", value: "II" },
                { name: "III", value: "III" },
                { name: "IV", value: "IV" },
                { name: "V", value: "V" },
              ]}
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="social"
              title="SSN (ex. 000000000)"
              safe
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="bday"
              title="Date of Birth"
            />
          </article>
          <h3 className="header">Customer Address</h3>
          <section className="retention-horizontal">
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="street"
              title="Street Address"
              required
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="additionalstreet"
              title="Street Address Line 2"
            />
          </section>
          <section className="retention-horizontal">
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="city"
              title="City"
              required
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="state"
              title="State"
              required
            />
            <Input
              formName="retentionExperianPullsPostBody"
              stateSetter={setRetentionExperianPullsPostBody}
              id="zip"
              title="Zipcode"
              required
            />
          </section>
          <article className="consent">
            <input
              type="checkbox"
              id="consent-box"
              onChange={handleConsentChange}
              value={consent}
            />
            <p className="consent-text">
              By checking this box, Rep confirms the following disclosure has
              been read to and confirmed by customer: <br /> You understand that
              by saying 'yes' you are providing 'verbal instructions' to
              (Momentum Solar) under the Fair Credit Reporting Act authorizing
              (Momentum Solar) to obtain information from your personal credit
              profile or other information from Experian. You authorize
              (Momentum Solar) to obtain such information solely to Prequalify
              you for financing
            </p>
          </article>
        </FormSection>
        {consent && (
          <Button
            onClick={handleSubmit}
            disabled={loading ? true : false}
            type={"submit"}
            title={"Submit"}
          />
        )}
      </form>
    );
  } else {
    return <article className="no-access">Access Denied</article>;
  }
}
