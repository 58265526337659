import "./App.css";
import React, { useState, useContext, useEffect } from "react";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Link,
} from "react-router-dom";
import { AuthContext } from "./Auth/AuthContext";
import PrivateRoute from "./Auth/PrivateRoute";

// Views (Alphabetical)
import Header from "./AppComponents/Header/Header";
import ForemanChecklistForm from "./ForemanChecklist/ForemanChecklist";
import Login from "./Login/Login";
import MomentumHeader from "./MomentumHeader/MomentumHeader";
import RetentionExperianPullForm from "./retention_experian_pulls/Form/Form";
import RetentionExperianPullResult from "./retention_experian_pulls/Result/Result";
import RoofLeaksForm from "./roof_leaks/Views/Form/Form";
import RoofingSalesJobs from "./RoofingSalesJobs/RoofingSalesJobs";
import ServiceRequestForm from "./service_request/Views/ServiceRequestForm/ServiceRequestForm";
import InspectionsForm from "./inspections/InspectionsForm";
import Roofsales from "./roof_leaks/Views/Roofsales.js/Roofsales";
import Loading from "./Loading";
import FieldSupervision from "./FieldSupervision/FieldSupervision";
import Hatch from "./hatch/hatch";
import NoForm from "./NoForm";

// Set up Context
const Context = React.createContext({});
export { Context };

// USE APPCOMPONENTS FOLDER - GLOBALCOMPONENTS AND FANCYCOMPONENTS ON WAY TO BEING DEPRECATED

function App() {
  // State
  const { authProviderLocal, isAuthenticated, account } =
    useContext(AuthContext);
  const [logout, setLogout] = useState("");
  const [azureAccount, setAzureAccount] = useState("");
  const [openFormSections, setOpenFormSections] = useState([]);
  const [creditResultOpen, setCreditResultOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valid, setValid] = useState("");
  const [error, setError] = useState("");
  const [foundProject, setFoundProject] = useState({});
  const [projNumber, setProjNumber] = useState("");
  const [didSearch, setDidSearch] = useState(false);
  const [unixVal, setUnixVal] = useState(0);
  const [submissionId, setSubmissionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [projectNumVal, setProjectNumVal] = useState("");

  useEffect(() => {
    if (valid !== "") {
      NotificationManager.success(``, `${valid}`, 3000);
      setValid("");
    }
    if (error !== "") {
      NotificationManager.error(``, `${error}`, 3000);
      setError("");
    }
  }, [valid, error]);

  const [inspectionsFormData, setInspectionsFormData] = useState({
    mts_inspectiondate: "",
    mts_state: "",
    mts_techname: "",
    mts_technameifnotshown: "",
    mts_inspectorname: "",
    mts_inspectiontype: [],
    mts_inspectionstatus: "911890000",
    mts_cleanjobsite: false,
    mts_isalldatacorrect: false,
    mts_inspectiondetails: "",
    mts_jobsitecleanlinessnotes: "",
    mts_fixmadeonsitenotes: "",
    img_electric_sticker: {},
    img_building_sticker: {},
    img_fire_sticker: {},
    img_photo_1: {},
    img_photo_2: {},
    img_photo_3: {},
    img_photo_4: {},
    img_photo_5: {},
    img_photo_6: {},
    img_photo_7: {},
    img_photo_8: {},
    img_photo_9: {},
    img_photo_10: {},
    img_photo_11: {},
    img_photo_12: {},
    img_photo_13: {},
    img_photo_14: {},
    img_photo_15: {},
    img_photo_16: {},
    img_photo_17: {},
    img_photo_18: {},
    img_photo_19: {},
    img_photo_20: {},
  });

  const [retentionExperianPullsPostBody, setRetentionExperianPullsPostBody] =
    useState({
      fname: "",
      mname: "",
      lname: "",
      generationCode: "",
      oppnum: "",
      repname: "",
      bday: "",
      state: "",
      city: "",
      zip: "",
      social: "",
      street: "",
      additionalstreet: "",
    });

  const [experianPullResult, setExperianPullResult] = useState({});

  const [roofLeaksFormData, setRoofLeaksFormData] = useState({
    mts_casetype: "962080000",
    mts_date: "",
    mts_arrivaltimeonsite: "",
    mts_departuretime: "",
    mts_currentweathercondition: "962080000",
    mts_rooftechnicianname: "",
    mts_roofingcompany: "In House",
    mts_roofcompanyifnotinhouse: "",
    mts_rooftype: [],
    mts_existingroofmaterial: [],
    mts_numberofshinglelayers: 0,
    mts_leakseverity: "962080000",
    mts_leakorigin: [],
    mts_roofrepaircost: "",
    mts_leakduetoerrorduringsolarinstallation: "962080000",
    leak_origin_img: "",
    current_roof_condition_img: "",
    interior_damage_img: "",
    interior_leak_location_img: "",
    repairs_made_img: "",
    mts_technotes: "",
    mts_customernotes: "",
  });

  const [serviceRequestFormData, setServiceRequestFormData] = useState({
    mts_servicecategory: "962080000",
    mts_statelookup: "",
    mts_office: "",
    mts_customername: "",
    mts_dateofservicecall: "",
    mts_omcrew: "",
    mts_crewcount: "",
    mts_crewmember1: "",
    mts_crewmember2: "",
    mts_otherpleaselist: "",
    mts_rootcauseofservice: "962080000",
    mts_truckrolltype: "962080000",
    mts_serviceoutcome: "962080000",
    mts_traveltimeonewayhours: "",
    mts_totaltimeonsitehours: "",
    mts_equipmentreplaced: [],
    mts_wasanydefectiveequipmentreplaced: false,
    mts_rmaformicroreplacement: false,
    mts_rmanumber: "",
    mts_microtype: [],
    mts_numberofmicrosreplaced: "0",
    mts_numberoffusesreplaced: "0",
    mts_numberofbreakersreplaced: "0",
    mts_trunkcablelengthreplacedfeet: "0",
    mts_numberofweathercapsreplaced: "0",
    mts_numberofqconnectorsreplaced: "0",
    mts_additionalnotes: "",
    img_before_service: {},
    img_after_service: {},
    img_envoy_green_light: {},
    img_micro_confirmation: {},
  });

  const [foremanChecklistFormData, setForemanChecklistFormData] = useState({
    mts_systeminstallationcomplete: false,
    mts_systemtested: false,
    mts_fieldassurancereponsite: false,
    mts_picksheetaccurate: false,
    mts_returnneeded: false,
    mts_electrical_and_pipe_approval: false,
    mts_mts_panel_layout_approval: false,
    mts_returncrewneeded: "962080000",
    mts_interioraccessneededatreturn: false,
  });

  const [fieldSupervisionData, setFieldSupervisionData] = useState({
    mts_customerelectricapproval: false,
    mts_customerpanelapproval: false,
    mts_onsiterevision: false,
    mts_safetysitemapuploaded: false,
    mts_mliftrequired: false,
    mts_jobsiteclean: false,
    mts_photoschecked: false,
  });

  const [hatchData, setHatchData] = useState({
    mts_firstname: "",
    mts_lastname: "",
    mts_phone: "",
    mts_addressline1: "",
    mts_addressline2: null,
    mts_city: "",
    mts_state: "",
    mts_zipcode: "",
    _mts_leadsource_value: "",
    mts_appointmenttimeind: "",
    mts_demoproducts: "",
  });

  // App
  return (
    <Context.Provider
      value={{
        setAzureAccount,
        setLogout,
        openFormSections,
        setOpenFormSections,
        retentionExperianPullsPostBody,
        setRetentionExperianPullsPostBody,
        experianPullResult,
        setExperianPullResult,
        roofLeaksFormData,
        setRoofLeaksFormData,
        serviceRequestFormData,
        setServiceRequestFormData,
        creditResultOpen,
        setCreditResultOpen,
        inspectionsFormData,
        setInspectionsFormData,
        foremanChecklistFormData,
        setForemanChecklistFormData,
        fieldSupervisionData,
        setFieldSupervisionData,
        isLoading,
        setIsLoading,
        setValid,
        setError,
        projNumber,
        setProjNumber,
        foundProject,
        setFoundProject,
        didSearch,
        setDidSearch,
        unixVal,
        setUnixVal,
        submissionId,
        setSubmissionId,
        loading,
        setLoading,
        projectNumVal,
        setProjectNumVal,
        hatchData,
        setHatchData,
      }}
    >
      <div className="App">
        {loading ? (
          <Loading />
        ) : (
          <>
            <MomentumHeader />
            <Router>
              <Switch>
                <Route path="/login">
                  <Login
                    login={authProviderLocal}
                    authCheck={isAuthenticated}
                  />
                </Route>
                {/* Hatch Forms */}
                <PrivateRoute path="/hatch">
                  <Header title="Hatch Lead Entry Form" />
                  <Hatch />
                </PrivateRoute>
                {/* Retention Experian Pulls */}
                <PrivateRoute path="/retention_experian_pulls/result">
                  <Header title="Retention - Experian Credit Pull" />
                  <RetentionExperianPullResult />
                </PrivateRoute>
                <PrivateRoute path="/retention_experian_pulls">
                  <Header title="Retention - Experian Credit Pull" />
                  <RetentionExperianPullForm />
                </PrivateRoute>
                {/* Service Request */}
                <PrivateRoute path="/service_request">
                  <Header title="Service Request" />
                  <ServiceRequestForm />
                </PrivateRoute>
                <PrivateRoute path="/roofing_sales_jobs">
                  <Header title="Roofing Sales - Jobs" />
                  <RoofingSalesJobs />
                </PrivateRoute>
                {/* Roof Leaks */}
                <Route path="/roof_leaks/:id">
                  <Header title="Roof Leaks" />
                  <RoofLeaksForm />
                </Route>
                <Route path="/roof_leaks">
                  <Header title="Roof Leaks" />
                  <RoofLeaksForm />
                </Route>
                {/* Momentum Home Sales */}
                <PrivateRoute path="/solar_roofing_sale">
                  <Header title="Momentum Home Sales" />
                  <Roofsales />
                </PrivateRoute>
                {/* Inspections */}
                <Route path="/inspection-submission">
                  <Header title="Inspection Form" />
                  <InspectionsForm />
                </Route>
                {/* Foreman Checklist */}
                <Route path="/foreman-checklist">
                  <Header title="Foreman Checklist" />
                  <ForemanChecklistForm />
                </Route>
                {/* Foreman Truck Roll */}
                <Route path="/field-supervision">
                  <Header title="Field Supervision Checklist" />
                  <FieldSupervision />
                </Route>
                <Route path="/">
                  <NoForm />
                </Route>
              </Switch>
            </Router>
            <NotificationContainer />
          </>
        )}
      </div>
    </Context.Provider>
  );
}

export default App;
