import React, { useContext, useEffect, useState } from "react";
import "./RoofingSalesJobs.css";
import { AuthContext } from "../Auth/AuthContext";
import Loading from "../Loading";
import { getRoofingSalesJobs } from "../API/localAPI";
import dayjs from "dayjs";

export default function RoofingSalesJobs({ setOpenView }) {
  const { azureAccount } = useContext(AuthContext);
  const [jobs, setJobs] = useState([]);
  const [searchedJobs, setSearchedJobs] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    console.log(azureAccount.account.userName);
    getRoofingSalesJobs(azureAccount.account.userName)
      .then((res) => {
        // const sortedJobs = res.sort((a,b) => {
        //   return b.mts_MHOPNumber.createdon.localeCompare(a.mts_MHOPNumber.createdon)
        // })
        console.log(res);

        setSearchedJobs(res);
        setJobs(res);
      })
      .catch(() =>
        alert(`error getting data for ${azureAccount.account.userName}`)
      );
  }, []);

  const searchJobs = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    setSearchVal(e.target.value);
    setSearchedJobs(
      jobs.filter((job) => {
        console.log(job);
        return Object.values(job).find((val) =>
          val
            .toString()
            .toUpperCase()
            .includes(e.target.value.toString().toUpperCase())
        );
      })
    );
  };

  return (
    <main className="tile">
      <button
        onClick={() => setOpenView()}
        style={{
          width: "100px",
          padding: "0.5em",
          border: "none",
          borderRadius: "7px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
          color: "white",
          fontSize: "0.9375rem",
          fontWeight: "600",
          backgroundColor: "#FF5A5F",
          alignSelf: 'flex-start',
          marginLeft: 10,
          marginTop: 10
        }}
      >
        Back
      </button>
      <h2 style={{ color: "#369" }}>{azureAccount.account.name}</h2>
      <input
        value={searchVal}
        onChange={(e) => searchJobs(e)}
        placeholder="Search all projects"
        style={{ height: 40, marginBottom: 10 }}
      />
      <article className="table">
        {!searchedJobs.length > 0 ? (
          <p style={{ width: "90vw" }}>Loading...</p>
        ) : (
          searchedJobs.map((job, i) => {
            return (
              <section className={i % 2 === 0 ? "white" : "grey"}>
                <section className={"table-row"}>
                  <article className="table-cell">
                    <article className="cell-header">Regarding</article>
                    {job.HouseholdName}
                  </article>
                  <article className="table-cell">
                    <article className="cell-header">Project Number</article>
                    {job.ProjectNumber}
                  </article>
                  <article className="table-cell">
                    <article className="cell-header">Scheduling Status</article>
                    {job.iis_schedulingstatus === 100000000
                      ? "Demo Scheduled"
                      : "N/A"}
                  </article>
                  <article className="table-cell">
                    <article className="cell-header">Start Time</article>
                    {dayjs(job.scheduledstart).format("YYYY-MM-DD H:mm A")}
                  </article>
                  <article className="table-cell">
                    <article className="cell-header">City</article>
                    {job.iis_city}
                  </article>
                  <article className="table-cell">
                    <article className="cell-header">State</article>
                    {job.iis_state && job.iis_state.toUpperCase()}
                  </article>
                </section>
                <section>
                  <article className="table-notes">
                    <article className="notes-header">
                      Roof Notes (Regarding)
                    </article>
                    <article className="notes">{job.RoofWorkNotes}</article>
                  </article>
                  <article className="table-notes">
                    <article className="notes-header">
                      Roof Sales Notes (Regarding)
                    </article>
                    <article className="notes">{job.RoofSalesNotes}</article>
                  </article>
                </section>
              </section>
            );
          })
        )}
      </article>
    </main>
  );
}
