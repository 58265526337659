import React, { useState, useContext } from "react";

const AuthContext = React.createContext({});
export { AuthContext };

const AuthContextProvider = ({ children, ...props }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [account, setAccount] = useState({});
  const [logout, setLogout] = useState();
  const [azureAccount, setAzureAccount] = useState("");
  const [authProviderLocal, setAuthProviderLocal] = useState();
  const [oldPath, setOldPath] = useState("");
  const [access, setAccess] = useState("");
  const [apiTest, setAPITest] = useState(0);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated,
        account,
        setAccount,
        logout,
        setLogout,
        authProviderLocal,
        setAuthProviderLocal,
        oldPath,
        setOldPath,
        access,
        setAccess,
        setAPITest,
        apiTest,
        azureAccount,
        setAzureAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;