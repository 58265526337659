import React, { useState, useEffect, useContext } from "react";
import './hatch.css';
import Input from "../AppComponents/Input/Input";
import AutoComplete from "../AppComponents/AutoComplete/AutoComplete";
import axios from "axios";
import Success from "../FancyComponents/Success/Success";
import dayjs from "dayjs";
import { Context } from "../App";
import Button from "../AppComponents/Button/Button";
import FormSection from "../AppComponents/FormSection/FormSection";
const Hatch = () => {
  const { setError, hatchData, setHatchData } = useContext(Context);

  // ADD ERROR VALIDATION FOR ZIP, PHONE, ETC

  // local state
  const [dropdowns, setDropdowns] = useState({
    states: [],
    leads: [],
    confirmed: [],
  });
  const [isLocalLoad, setIsLocalLoad] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  // watchers
  useEffect(() => {
    axios
      .get(
        `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/new_hatch_lead_entry`
      )
      .then((v) => {
        console.log(v);
        setDropdowns(v.data);
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      });
  }, []);

    // JSX constructors
  const dateTimeGen = (
    label,
    changeHandler,
    id = "",
    helperText = "",
    error = false,
    type = ""
  ) => {
    return <Input 
      formName="hatchData"
      stateSetter={changeHandler}
      required
      type="datetime"
      title={label}
      id={id}
    />
  };

  const autoCompleteGen = (
    label,
    values,
    changeHandler,
    id = "",
    helperText = "",
    error = false
  ) => {
    return (
      <AutoComplete 
      label={label}
      options={values}
      onChange={changeHandler}
      labelKey={"text"}
    />
    ) 
  };

  //handlers
  const autoChange = (val) => (e, n) => {
    console.log(val);
    console.log(n);
    if (n) {
      setHatchData({ ...hatchData, [val]: n.value });
    } else {
      setHatchData({ ...hatchData, [val]: "" });
    }
  };

  const validate = (body) => {
    let error = false;

    Object.keys(body).forEach(key => {
      if (!body[key] && key !== 'mts_addressline2') {
        error = key
      }
    })

    return error
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(hatchData);
    //first validate
    setAttemptedSubmit(true);
    let check = validate(hatchData);
    
    let finalData = {...hatchData, mts_phone: hatchData.mts_phone.toString(), mts_zipcode: hatchData.mts_zipcode.toString() };

    if (!check) {
      setAttemptedSubmit(false);
      setIsLocalLoad(true);
      axios
        .post(
          `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/forms/new_hatch_lead`,
          finalData
        )
        .then((v) => {
          setTimeout(() => {
            setHatchData({
              mts_firstname: "",
              mts_lastname: "",
              mts_phone: "",
              mts_addressline1: "",
              mts_addressline2: null,
              mts_city: "",
              mts_state: "",
              mts_zipcode: "",
              _mts_leadsource_value: "",
              mts_appointmenttimeind: "",
              mts_demoproducts: "",
            });
            setIsLocalLoad(false);
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
          setIsLocalLoad(false);
          setError(e);
        });
    } else {
      alert(check);
    }
  };

  return !isLocalLoad ? (
    <FormSection title="Lead Information">
      <div className="two_column">
        <Input
          formName={"hatchData"}
          title="First Name"
          id="mts_firstname"
          required
          stateSetter={setHatchData}
        />
        <Input
          formName={"hatchData"}
          title="Last Name"
          id="mts_lastname"
          required
          stateSetter={setHatchData}
        />
      </div>
      <div className="one_column">
        <Input
          formName={"hatchData"}
          title="Phone Number"
          id="mts_phone"
          type="number"
          required
          stateSetter={setHatchData}
        />
      </div>
      <div className="one_column">
        <Input
          formName={"hatchData"}
          title="Address 1"
          id="mts_addressline1"
          required
          stateSetter={setHatchData}
        />
      </div>
      <div className="one_column">
        <Input
          formName={"hatchData"}
          title="Address 2"
          id="mts_addressline2"
          stateSetter={setHatchData}
        />
      </div>
      <div className="three_column">
        <Input
          formName={"hatchData"}
          title="City"
          id="mts_city"
          required
          stateSetter={setHatchData}
        />
        {autoCompleteGen(
          "State",
          dropdowns.states,
          autoChange("mts_state"),
          "mts_state",
          "State is required",
          attemptedSubmit && !hatchData.mts_state ? true : false
        )}
        <Input
          formName={"hatchData"}
          title="ZIP"
          id="mts_zipcode"
          type="number"
          required
          stateSetter={setHatchData}
        />
      </div>
      <div className="one_column">
        {dateTimeGen(
          "Appointment Date and Time",
          setHatchData,
          "mts_appointmenttimeind",
          "Appointment Date and Time is required",
          attemptedSubmit && !hatchData.mts_appointmenttimeind ? true : false
        )}
      </div>
      <div className="two_column">
        {autoCompleteGen(
          "Confirmed",
          dropdowns.confirmed,
          autoChange("mts_demoproducts"),
          "mts_demoproducts",
          "",
          false
        )}
        {/* {textFieldGen("Lead Source", "")} */}
        {autoCompleteGen(
          "Lead Source",
          dropdowns.lead_sources,
          autoChange("_mts_leadsource_value"),
          "_mts_leadsource_value",
          "Lead Source is required",
          attemptedSubmit && !hatchData._mts_leadsource_value ? true : false
        )}
      </div>

      {isLocalLoad ? (
        <button
          style={{
            padding: "1em",
            marginTop: "0.5em",
            borderRadius: "7px",
            background: "#336699",
            color: "white",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
            border: "none",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          Loading...
        </button>
      ) : (
        <Button onClick={submitHandler} title="Submit" type="submit" />
      )}
    </FormSection>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        width: "100vw",
      }}
    >
      <Success />
    </div>
  );
};

export default Hatch;
