import React, { useState, useContext } from "react";
import { RoofSalesStyle, FormCard } from "../../../Styling/styles";
import { Context } from "../../../App";
import RoofForm from "./RoofForm";
import RoofingSalesJobs from "../../../RoofingSalesJobs/RoofingSalesJobs";
import Button from "../../../AppComponents/Button/Button";
import "../../../AppComponents/Button/Button.css";

const { getUserInfo, newRoof } = require("../../../API/localAPI");

const Roofsales = ({ ...props }) => {
  // state
  const { setValid, setError } = useContext(Context);
  const [didSearch, setDidSearch] = useState(false);
  const [projNumber, setProjNumber] = useState("");
  const [foundProject, setFoundProject] = useState({});
  const [unix, setUnix] = useState("");
  const [id, setID] = useState("");
  const [openView, setOpenView] = useState("");

  // handlers
  const searchClick = async (e) => {
    e.preventDefault();
    if (projNumber === "") {
      setError("make sure to type a project number");
    } else {
      let found = await getUserInfo(projNumber);
      console.log(found);
      if (found.length > 0) {
        console.log("start");
        setFoundProject(found[0]);
      } else {
        setError("No project found. Try again");
        setFoundProject("");
      }
    }
  };
  const reset = () => {
    setProjNumber("");
    setFoundProject({});
    setDidSearch(false);
  };
  const clickedYes = (e) => {
    e.preventDefault();
    console.log(projNumber);

    newRoof(projNumber)
      .then((v) => {
        setUnix(v.unix);
        setID(v.mts_momentumhomesalesid);
        console.log("resetting didSearch");
        setDidSearch(true);
      })
      .catch((e) => {
        console.log(e);
        console.log("something broke");
      });
  };
  const clickedNo = (e) => {
    e.preventDefault();
    setDidSearch(false);
    setProjNumber("");
    setFoundProject({});
  };

  // JSX elements
  const viewSelection = (
    <article
      style={{
        backgroundColor: "white",
        padding: 20,
        margin: 20,
        borderRadius: 8,
      }}
    >
      <Button
        type="primary"
        onClick={() => setOpenView("form")}
        title="Create New"
      />
      <Button
        type="primary"
        onClick={() => setOpenView("list")}
        title="View Submissions"
      />
    </article>
  );

  const searchAndForm = (
    <FormCard>
      <h3>Solar Roofing Sales</h3>
      {!didSearch ? (
        <div
          className="initial_search_div"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {Object.keys(foundProject).length === 0 && (
            <>
              <input
                className="input_initial_search"
                style={{
                  marginTop: "1em",
                  marginBottom: "1em",
                  width: "200px",
                  padding: "1em",
                }}
                value={projNumber}
                onChange={(e) => setProjNumber(e.currentTarget.value)}
                placeholder="search for a project number"
              />
              <button
                style={{
                  padding: "0.5em",
                  marginBottom: "0.5em",
                  border: "none",
                  borderRadius: "7px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                  color: "white",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                  backgroundColor: "rgb(24 119 242)",
                }}
                onClick={searchClick}
              >
                search
              </button>
            </>
          )}
          {Object.keys(foundProject).length > 0 && (
            <>
              <p style={{ marginTop: "0.5em", fontWeight: "bold" }}>
                Is this the correct customer?
              </p>
              <div
                style={{
                  background: "#add8e6",
                  padding: "0.7em",
                  marginTop: "0.5em",
                  marginBottom: "0.5em",
                  borderRadius: "8px",
                }}
              >
                <p>{foundProject.iis_householdid.name}</p>
                <p>{`${foundProject.iis_householdid.address1_line1}, ${foundProject.iis_householdid.address1_city}, ${foundProject.iis_householdid["iis_state@OData.Community.Display.V1.FormattedValue"]}`}</p>

                <button
                  onClick={clickedYes}
                  style={{
                    width: "100px",
                    padding: "0.5em",
                    marginRight: "0.5em",
                    marginTop: "0.5em",
                    border: "none",
                    borderRadius: "7px",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                    color: "white",
                    fontSize: "0.9375rem",
                    fontWeight: "600",
                    backgroundColor: "#25d366",
                  }}
                >
                  Yes
                </button>
                <button
                  onClick={clickedNo}
                  style={{
                    width: "100px",
                    padding: "0.5em",
                    border: "none",
                    borderRadius: "7px",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
                    color: "white",
                    fontSize: "0.9375rem",
                    fontWeight: "600",
                    backgroundColor: "#FF5A5F",
                  }}
                >
                  No
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <RoofForm
          unix={unix}
          id={id}
          projNumber={projNumber}
          foundProject={foundProject}
          reset={reset}
        />
      )}
    </FormCard>
  );

  // render
  if (!openView) return viewSelection;

  if (openView === "list") return <RoofingSalesJobs setOpenView={setOpenView} />;

  if (openView === "form") return searchAndForm;
};

export default Roofsales;
