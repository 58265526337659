import React, {useContext} from 'react';
import './FormSection.css';
import { Context } from '../../App';
import chevron from './chevron.svg';

export default function FormSection({title, children}) {
  const { openFormSections, setOpenFormSections } = useContext(Context);

  const handleClick = e => {
    if (!openFormSections.includes(e.target.id)) {
      setOpenFormSections(prev => {
        return [...prev, e.target.id];
      })
    } else {
      setOpenFormSections(prev => {
        const selectionRemoved = prev.filter(el => el !== e.target.id)
        return selectionRemoved;
      })
    }
  }

  return (
    <section className="form-section">
      <header className="form-section-header">
        <h1 className="header-text">{title}</h1>
        <img className={openFormSections.includes(title) ? "rotate" : ""} onClick={handleClick} src={chevron} id={title} alt="Dropdown Button" />
      </header>
      {openFormSections.includes(title) && children}
    </section>
  )
}
