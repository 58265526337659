import React, { useEffect, useContext, useState } from "react";
// import "./ForemanChecklistForm.css";
import Dropdown from "../AppComponents/Dropdown/Dropdown";
import Input from "../AppComponents/Input/Input";
import { Context } from "../App";
import {
  getOffices,
  getServiceRequestEmployees,
  getAllStates,
  updateForemanChecklist,
} from "../API/localAPI";
import FormSection from "../AppComponents/FormSection/FormSection";
import Autocomplete from "../AppComponents/AutoComplete/AutoComplete";
import ProjectSearch from "../ProjectSearch/ProjectSearch";
import Button from "../AppComponents/Button/Button";

export default function ForemanChecklistForm() {
  // State
  const {
    foundProject,
    didSearch,
    unixVal,
    projNumber,
    foremanChecklistFormData,
    setForemanChecklistFormData,
    setDidSearch,
    setError,
    setLoading,
    setValid,
  } = useContext(Context);

  const [employees, setEmployees] = useState({});
  const [states, setStates] = useState({});
  const [offices, setOffices] = useState({});

  useEffect(() => {
    getServiceRequestEmployees()
      .then((res) => setEmployees(res.data.value))
      .catch((err) => {
        alert(err);
        setError("Failed to load employees. Please reload.");
      });

    getAllStates()
      .then((res) => setStates(res.data.value))
      .catch((err) => {
        alert(err);
        setError("Failed to load states. Please reload.");
      });
    getOffices()
      .then((res) => setOffices(res))
      .catch((err) => {
        alert(err);
        setError("Failed to load offices. Please reload.");
      });
  }, []);

  // Handlers
  const handleForemanChange = (e, n) => {
    if (n) {
      setForemanChecklistFormData((prev) => {
        return {
          ...prev,
          mts_foreman: n.contactid,
        };
      });
    } else {
      setForemanChecklistFormData((prev) => {
        return {
          ...prev,
          mts_foreman: "",
        };
      });
    }
  };

  const handleOfficeChange = (e, n) => {
    if (n) {
      setForemanChecklistFormData((prev) => {
        return {
          ...prev,
          mts_foremanoffice: n.mts_momentumofficeid,
        };
      });
    } else {
      setForemanChecklistFormData((prev) => {
        return {
          ...prev,
          mts_foremanoffice: "",
        };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(foremanChecklistFormData);
    const cleanedBody = { ...foremanChecklistFormData };

    // correct types
    cleanedBody.mts_installtotalhours = parseInt(cleanedBody.mts_installtotalhours)
    cleanedBody.mts_returnhoursneeded = parseInt(cleanedBody.mts_returnhoursneeded)
    cleanedBody.mts_roundtripdrivetime = parseInt(cleanedBody.mts_roundtripdrivetime)


    if (cleanedBody.mts_safetychecklist) {
      cleanedBody.mts_safetychecklist =
        foremanChecklistFormData.mts_safetychecklist.join(",");
    }

    updateForemanChecklist(
      unixVal,
      projNumber,
      foundProject.iis_projectid,
      cleanedBody
    )
      .then((res) => {
        setLoading(false);
        setValid("Success");
        setDidSearch(false);
        setForemanChecklistFormData({
          mts_systeminstallationcomplete: false,
          mts_systemtested: false,
          mts_fieldassurancereponsite: false,
          mts_picksheetaccurate: false,
          mts_returnneeded: false,
          mts_electrical_and_pipe_approval: false,
          mts_mts_panel_layout_approval: false,
          mts_returncrewneeded: "962080000",
          mts_interioraccessneededatreturn: false,
        });
      })
      .catch((err) => {
        setLoading(false);
        setError("Unsuccessful. Try again");
        alert(err);
      });
  };

  // JSX Elements
  const projectInformation = (
    <section className="inputs">
      <Autocomplete
        label={"Foreman"}
        options={employees}
        onChange={handleForemanChange}
        labelKey={"fullname"}
      />
      <Autocomplete
        label={"Foreman Office"}
        options={offices}
        onChange={handleOfficeChange}
        labelKey={"mts_name"}
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_dateofwork" title="Date of Work" type="date" />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_starttime" title="Start Time" type="datetime" />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_endtime" title="End Time" type="datetime" />
    </section>
  );

  const projectDocumentation = (
    <section className="inputs">
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_electrical_and_pipe_approval"
        title="Was electrical equipment layout and pipe run location approved by customer?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_mts_panel_layout_approval"
        title="Was the panel layout and roof pipe run approved by customer?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_systeminstallationcomplete"
        title="System Installation Complete?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_systemtested"
        title="System Tested?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData} 
        id="mts_installtotalhours"
        title="Install Total Hours"
        type="number"
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData} 
        id="mts_roundtripdrivetime"
        title="Round Trip Drive Time"
        type="number"
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_crewmembersonsite" title="Crew Members On Site" />
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_fieldassurancereponsite"
        title="Field Assurance Rep On Site?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_picksheetaccurate"
        title="Pick Sheet Accurate?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData} 
        id="mts_atticinitialinspectionby"
        title="Attic Initial Inspection By"
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData} 
        id="mts_mts_anchorinstalledremovedby"
        title="Anchor Installed/Removed By"
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData} 
        id="mts_drilledbasedsealedby"
        title="Drilled/Based/Sealed By"
        type="multi-input"
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_flashedby" title="Flashed By" />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_atticcheckby" title="Attic Check By" />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_baseinitialed" title="Base Initialed" />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData}  id="mts_foremanchecklistnotes" title="Notes" type="textarea" />
    </section>
  );

  const projectReturnInformation = (
    <section className="inputs">
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_returnneeded"
        title="Return Needed?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        id="mts_returncrewneeded"
        title="Return Crew Needed"
        options={[
          { name: "Service Crew", value: "962080000" },
          { name: "Inspection Crew", value: "962080001" },
          { name: "Install Crew", value: "962080002" },
        ]}
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData} 
        id="mts_returnhoursneeded"
        title="Return Hours Needed"
        type="number"
      />
      <Dropdown
        formName="foremanChecklistFormData"
        stateSetter={setForemanChecklistFormData}
        required
        id="mts_interioraccessneededatreturn"
        title="Interior Access Needed at Return?"
        options={[
          { name: "No", value: false },
          { name: "Yes", value: true },
        ]}
      />
      <Input formName="foremanChecklistFormData" stateSetter={setForemanChecklistFormData} 
        id="mts_whatneedtobecompletedatreturn"
        title="What needs to be completed at return?"
        type="textarea"
      />
    </section>
  );

  return !didSearch ? (
    <ProjectSearch
      endpoint="new_foreman_checklist"
      idForPhoto="mts_foremanchecklistid"
    />
  ) : (
    <form onSubmit={handleSubmit} className="foreman-checklist-form">
      <FormSection title="General Information" children={projectInformation} />
      <FormSection
        title="Documentation / Progress"
        children={projectDocumentation}
      />
      <FormSection
        title="Return Information"
        children={projectReturnInformation}
      />
      <Button onClick={handleSubmit} type={"submit"} title={"Submit"} />
    </form>
  );
}
