import React, {useContext} from 'react';
import './Result.css';
import { Context } from "../../App";
import  { Redirect } from 'react-router-dom'
import Button from '../../AppComponents/Button/Button';


export default function Result() {
  const { experianPullResult, setExperianPullResult, setRetentionExperianPullsPostBody, creditResultOpen, setCreditResultOpen } = useContext(Context);


  //Handlers
  const handleClick = e => {
    e.preventDefault();
    setExperianPullResult({});
    setRetentionExperianPullsPostBody({
      fname: "",
      mname: "",
      lname: "",
      oppnum: "",
      repname: "",
      bday: "",
      state: "",
      city: "",
      zip: "",
      social: "",
      street: "",
      additionalstreet: "",
    });
    setCreditResultOpen(false);
  }
  
  return (
    !creditResultOpen ? <Redirect to="/retention_experian_pulls" /> : 
    <main className="result-card">
      <article className="">
        Credit Score:<br/>{experianPullResult.mts_creditscore}
      </article>
      <article className="">
        Result:<br/>{experianPullResult.mts_msescreditnotes}
      </article>
      <Button onClick={handleClick} type={'submit'} title={"Back to New Request"} />

    </main>
  )
}
