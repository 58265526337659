import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../../App";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import LargeFileUpload from "../../../GlobalComponents/LargeFileUpload/LargeFileUpload";
const {
  getRoofSalesEntry,
  patchRoofSales,
  postLargeFiles,
} = require("../../../API/localAPI");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const RoofForm = ({ ...props }) => {
  const classes = useStyles();
  const [isLocalLoad, setLocalLoad] = useState(false);
  const { setError, setValid } = useContext(Context);
  const [dropdowns, setDropdowns] = useState({});
  const [images, setImages] = useState([]);
  const [salesForm, setSalesForm] = useState({
    mts_dateofappointment: "",
    mts_outcome: "",
    mts_salesrep: "",
    mts_concessionyn: false,
    mts_projecttype: [],
    mts_hoa: false,
    mts_notes: "",
    contract: "",
  });
  const requiredFields = [
    "mts_dateofappointment",
    "mts_salesrep",
    "mts_outcome",
    "mts_projecttype",
    "mts_contract",
    "mts_notes",
  ];

  const handleChange = (event) => {
    setSalesForm({
      ...salesForm,
      mts_projecttype: event.target.value,
    });
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Handlers
  const handleChangePhoto = async (e) => {
    e.preventDefault();
    console.log(e.target.type);
    if (e.target.type === "file") {
      let base = await convertBase64(e.target.files[0]);
      console.log(base);
      let valid = base.split(",")[1];
      console.log({ ...salesForm, [e.target.id]: valid });
      setSalesForm((prev) => {
        return {
          ...prev,
          [e.target.id]: valid,
        };
      });
    }
  };

  function Dropdown({ id, title, options }) {
    const optionInputs = options.map((element) => {
      return (
        <option key={`${element.key}_${element.name}`} value={element.key}>
          {element.name}
        </option>
      );
    });

    return (
      <article className='input-wrapper'>
        <label style={{ fontWeight: "bold" }} htmlFor={title}>
          {title}:
        </label>
        <select
          style={{ minWidth: 100, height: 40 }}
          className="shadow"
          name={title}
          required={requiredFields.includes(id)}
          id={id}
          onChange={changeHandler}
          value={salesForm[id]}
        >
          {optionInputs}
        </select>
      </article>
    );
  }

  const validate = (body) => {
    if (body.mts_salesrep === "") {
      return "Sales Rep";
    }

    if (body.mts_dateofappointment === "") {
      return "Date of Appointment";
    }

    if (body.mts_outcome === "") {
      return "Outcome";
    }

    if (body.mts_projecttype === "") {
      return "Project Type";
    }

    if (body.mts_notes === "") {
      return "Notes";
    } else {
      return false;
    }
  };

  const getEntries = async () => {
    let found = await getRoofSalesEntry();
    console.log(found);
    setDropdowns(found);
    setSalesForm({
      ...salesForm,
      mts_outcome: found.mts_outcome[0].key,
      mts_projecttype: [found.mts_projecttype[0].key],
    });
  };

  useEffect(() => {
    getEntries();
  }, []);

  const changeHandler = (e) => {
    e.preventDefault();
    setSalesForm((prev) => {
      return {
        ...prev,
        [e.target.id]: e.target.value,
      };
    });
  };

  const submitSalesForm = async () => {
    console.log("submitted");
    setLocalLoad(true);
    console.log(salesForm);
    console.log(props.unix);
    console.log(props.foundProject);
    console.log(props.projNumber);
    let validation = validate(salesForm);
    if (validation) {
      setError(`Missing value for ${validation}`);
      setLocalLoad(false);
    } else {
      let newBody = salesForm;
      if (newBody.mts_concessionamount) {
        newBody.mts_concessionamount = +newBody.mts_concessionamount;
      }
      if (newBody.mts_pricesoldquotedprice) {
        newBody.mts_pricesoldquotedprice = +newBody.mts_pricesoldquotedprice;
      }
      if (newBody.mts_finance1amount) {
        newBody.mts_finance1amount = +newBody.mts_finance1amount;
      }
      if (newBody.mts_finance2amount) {
        newBody.mts_finance2amount = +newBody.mts_finance2amount;
      }
      newBody.mts_projecttype = newBody.mts_projecttype.join(",");
      try {
        let patch = await patchRoofSales(
          props.foundProject.iis_projectid,
          props.unix,
          props.projNumber,
          newBody
        );
        let allImages = await postLargeFiles(
          "objectid_mts_momentumhomesales@odata.bind",
          "mts_momentumhomesaleses",
          props.id,
          images
        );

        console.log("here is form");
        console.log(salesForm);
        setValid("Successfully uploaded");
        props.reset();
        setLocalLoad(false);
      } catch (e) {
        console.log("fail submit");
        setError("Error uploading");
        setLocalLoad(false);
      }

      // setSalesForm({
      //   mts_dateofappointment: "",
      //   mts_outcome: "",
      //   mts_salesrep: "",
      //   mts_concessionyn: false,
      //   mts_projecttype: [],
      //   mts_hoa: false,
      //   mts_notes: "",
      //   contract: "",
      // });
    }
    console.log(validation);
  };

  const autoChangeHandlerSales = (e, n) => {
    console.log(n);
    if (n) {
      setSalesForm({ ...salesForm, mts_salesrep: n.key });
    } else {
      setSalesForm({ ...salesForm, mts_salesrep: "" });
    }
  };
  return (
    <div style={{textAlign: 'left'}}>
      <section className="roof-sales-form">
        {/* <article >
              <label style={{fontWeight:"bold"}} htmlFor="mts_casenumber">Case Number:</label>
              <p>{id}</p>
            </article> */}
        <article style={{marginBottom: 10}}>
          <label style={{ fontWeight: "bold" }} htmlFor="mts_dateofappointment">
            Date of Appointment
          </label>
          <input
            style={{marginLeft: 10}}
            onChange={changeHandler}
            className="shadow"
            id="mts_dateofappointment"
            required
            type="date"
          />
        </article>
        <Autocomplete
          style={{ width: "80vw", boxShadow: "none", marginBottom: 20 }}
          id="combo-box-demo"
          options={dropdowns.contacts}
          onChange={autoChangeHandlerSales}
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => (
            <TextField {...params} label="Sales Rep" variant="outlined" />
          )}
        />
        {/* <Dropdown
          id="mts_casetype"
          title="Sales Rep"
          options={[
            { name: "Roof Leak", key: "962080000" },
            { name: "Exterior Damage", key: "962080001" },
            { name: "Interior Damage", key: "962080002" },
          ]}
        /> */}
        <Dropdown
          style={{marginBottom: 40}}
          id="mts_outcome"
          title="Outcome"
          onChange={changeHandler}
          options={dropdowns.mts_outcome ? dropdowns.mts_outcome : []}
        />
        <article style={{marginTop: 20, marginBottom: 20}}>
          <label
            style={{ fontWeight: "bold" }}
            htmlFor="mts_pricesoldquotedprice"
          >
            Price Sold/Quoted Price:
          </label>
          <input
            style={{marginLeft: 10}}
            onChange={changeHandler}
            type="number"
            min={0.0}
            step={0.01}
            className="shadow"
            id="mts_pricesoldquotedprice"
          />
        </article>
        <Dropdown
          id="mts_concessionyn"
          title="Concession (Y/N)"
          onChange={changeHandler}
          options={[
            { name: "Yes", key: true },
            { name: "No", key: false },
          ]}
          value={salesForm.mts_concessionyn}
          style={{marginBottom: 20}}
        />
        <article style={{marginTop: 20, marginBottom: 20}}>
          <label style={{ fontWeight: "bold" }} htmlFor="mts_concessionamount">
            Concession Amount:
          </label>
          <input
            style={{marginLeft: 10}}
            onChange={changeHandler}
            type="number"
            min={0.0}
            step={0.01}
            className="shadow"
            id="mts_concessionamount"
          />
        </article>
        {/* <Dropdown
          id="mts_projecttype"
          onChange={changeHandler}
          required
          title="Project Type"
          options={
            Object.keys(dropdowns).length > 0 ? dropdowns.mts_projecttype : []
          }
        /> */}
        {Object.keys(dropdowns).length > 0 && (
          <FormControl className={classes.formControl} style={{marginBottom: 40}}>
            <label id="demo-mutiple-checkbox-label">
              Project Type
            </label>
            <Select
              labelId="checkbox-label"
              id="checkbox"
              multiple
              required
              value={
                typeof salesForm.mts_projecttype === "string"
                  ? salesForm.mts_projecttype.split(",")
                  : salesForm.mts_projecttype
              }
              onChange={handleChange}
              input={<Input />}
              renderValue={(selected) => {
                console.log("here is selected");
                console.log(selected);
                let final = [];
                let loop =
                  typeof selected === "string" ? selected.split(",") : selected;
                loop.forEach((u) => {
                  let find = dropdowns.mts_projecttype
                    ? dropdowns.mts_projecttype.find((i) => i.key === u)
                    : null;
                  if (find) {
                    final.push(find.name);
                  }
                });
                return final.join(", ");
              }}
              MenuProps={MenuProps}
            >
              {dropdowns.mts_projecttype.map((n) => (
                <MenuItem key={n.name} value={n.key}>
                  <Checkbox
                    checked={
                      typeof salesForm.mts_projecttype === "string"
                        ? salesForm.mts_projecttype
                            .split(",")
                            .find((x) => x === n.key)
                          ? true
                          : false
                        : salesForm.mts_projecttype.find((x) => x === n.key)
                        ? true
                        : false
                    }
                  />
                  <ListItemText primary={n.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/* <InputLabel id="demo-mutiple-name-label">Project Type</InputLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          value={[]}
          // onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
        >
          {Object.keys(dropdowns).length > 0 &&
            dropdowns.mts_projecttype.map((i) => (
              <MenuItem key={i.key} value={i.key}>
                {i.name}
              </MenuItem>
            ))}
        </Select> */}
        <article style={{marginBottom: 20}}>
          <label style={{ fontWeight: "bold" }} htmlFor="mts_roofsize">
            Roof Size:
          </label>
          <input
            style={{marginLeft: 10}}
            onChange={changeHandler}
            type="text"
            className="shadow"
            id="mts_roofsize"
          />
        </article>
        <Dropdown
          id="mts_hoa"
          onChange={changeHandler}
          required
          title="HOA"
          options={[
            { name: "Yes", key: true },
            { name: "No", key: false },
          ]}
        />
        <article style={{marginTop: 20, marginBottom: 20}}>
          <label style={{ fontWeight: "bold" }} htmlFor="mts_dateofappointment">
            Reschedule Date:
          </label>
          <input
            style={{marginLeft: 10}}
            onChange={changeHandler}
            className="shadow"
            id="mts_rescheduleddateandtime"
            type="datetime-local"
          />
        </article>
      </section>
      <textarea
        id="mts_notes"
        required
        maxLength="500"
        placeholder="Notes"
        rows="4"
        cols="40"
        onChange={changeHandler}
        style={{ marginTop: "1.5em", marginBottom: 20 }}
        // onChange={notesChange}
      ></textarea>
      <div className="roof_sales_finance">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginRight: "1.5em",
          }}
        >
          <Dropdown
            id="mts_financeproduct1"
            required
            value={salesForm.mts_financeproduct1}
            onChange={changeHandler}
            title="Finance Product 1"
            options={
              Object.keys(dropdowns).length > 0
                ? [{ key: "", name: "N/A" }, ...dropdowns.mts_financeproducts]
                : []
            }
          />
          <article style={{marginTop: 20, marginBottom: 20}}>
            <label style={{ fontWeight: "bold" }} htmlFor="mts_finance1amount">
              Finance 1 Amount:
            </label>
            <input
              style={{marginLeft: 10}}
              onChange={changeHandler}
              type="number"
              min={0.0}
              step={0.01}
              className="shadow"
              id="mts_finance1amount"
            />
          </article>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginBottom: 20
          }}
        >
          <Dropdown
            id="mts_financeproduct2"
            onChange={changeHandler}
            required
            value={salesForm.mts_financeproduct2}
            title="Finance Product 2"
            options={
              Object.keys(dropdowns).length > 0
                ? [{ key: "", name: "N/A" }, ...dropdowns.mts_financeproducts]
                : []
            }
          />
          <article style={{marginTop: 20, marginBottom: 20}}>
            <label style={{ fontWeight: "bold" }} htmlFor="mts_finance2amount">
              Finance 2 Amount:
            </label>
            <input
              style={{marginLeft: 10}}
              onChange={changeHandler}
              type="number"
              min={0.0}
              step={0.01}
              className="shadow"
              id="mts_finance2amount"
            />
          </article>
        </div>
      </div>
      <div>
        <article className="form-section" style={{ marginLeft: 0 }}>
          <h1>Photos</h1>
          <section className="image-grid">
            <article className="file-input">
              <LargeFileUpload stateSetter={setImages} id="Contract Upload" />
            </article>
          </section>
          <section className="image-grid">
            <article className="file-input">
              <LargeFileUpload stateSetter={setImages} id="Upload 2" />
            </article>
          </section>
          <section className="image-grid">
            <article className="file-input">
              <LargeFileUpload stateSetter={setImages} id="Upload 3" />
            </article>
          </section>
          <section className="image-grid">
            <article className="file-input">
              <LargeFileUpload stateSetter={setImages} id="Upload 4" />
            </article>
          </section>
          <section className="image-grid">
            <article className="file-input">
              <LargeFileUpload stateSetter={setImages} id="Upload 5" />
            </article>
          </section>
        </article>
      </div>
      {isLocalLoad ? (
        <article style={{width: '100%', textAlign: 'center'}}><button
          style={{
            padding: "1.5em",
            borderRadius: "7px",
            background: "#336699",
            color: "white",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
            border: "none",
            fontWeight: "bold",
            fontSize: "1rem"
          }}
        >
          Loading...
        </button>
        </article>
      ) : (
        <article style={{width: '100%', textAlign: 'center'}}>
        <button
          style={{
            padding: "1.5em",
            borderRadius: "7px",
            background: "#336699",
            color: "white",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px",
            border: "none",
            fontWeight: "bold",
            fontSize: "1rem"
          }}
          onClick={submitSalesForm}
        >
          SUBMIT
        </button>
        </article>
      )}
    </div>
  );
};

export default RoofForm;
